function AddIconFilled({ className }) {
  return (
    <svg
      viewBox="0 0 33 32"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ddd_42924_3734)">
        <rect x="4.5" y="3" width="24" height="24" rx="12" fill="#D5DBE5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 9C16.9142 9 17.25 9.33579 17.25 9.75V14.25H21.75C22.1642 14.25 22.5 14.5858 22.5 15C22.5 15.4142 22.1642 15.75 21.75 15.75H17.25V20.25C17.25 20.6642 16.9142 21 16.5 21C16.0858 21 15.75 20.6642 15.75 20.25V15.75H11.25C10.8358 15.75 10.5 15.4142 10.5 15C10.5 14.5858 10.8358 14.25 11.25 14.25H15.75V9.75C15.75 9.33579 16.0858 9 16.5 9Z"
          fill="#687182"
        />
      </g>
      <defs>
        <filter
          id="filter0_ddd_42924_3734"
          x="0.5"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_42924_3734"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.272 0 0 0 0 0.294667 0 0 0 0 0.34 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_42924_3734"
            result="effect2_dropShadow_42924_3734"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="dilate"
            in="SourceAlpha"
            result="effect3_dropShadow_42924_3734"
          />
          <feOffset />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.596078 0 0 0 0 0.630065 0 0 0 0 0.698039 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_42924_3734"
            result="effect3_dropShadow_42924_3734"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_42924_3734"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default AddIconFilled;
