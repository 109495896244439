/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { IoLocationOutline } from "react-icons/io5";
import BaseDataFormModal from "views/admin/default/components/BaseDataFormModal";
import SectorIcon from "views/admin/default/components/icons/SectorIcon";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import { useWindowSize } from "@uidotdev/usehooks";
import Paginate from "components/pagination";

function BaseData() {
  const [active, setActive] = useState(0);
  const [editData, setEditData] = useState(undefined);
  const [page, setPage] = useState(1);
  const size = useWindowSize();

  const [{ data, loading, error }, fetch] = useAxios(
    { url: "/base_data/region/", params: { page: page || 1 } },
    { manual: true, useCache: false }
  );

  const [{ loading: postLoading, error: postErr }, post] = useAxios(
    {
      url: "/base_data/region/",
      method: editData ? "PUT" : "POST",
    },
    { manual: true }
  );

  const handleSubmit = async (data, update) => {
    try {
      if (active === 0) {
        return post({
          url: `/base_data/region/${update ? editData.id + "/" : ""}`,
          data: {
            region_name: data.name,
            region_description: data.description,
            method: update ? "PUT" : "POST",
          },
        }).then(() => fetch("base_data/region/"));
      } else if (active === 1) {
        return post({
          url: `/base_data/zone/${update ? editData.id + "/" : ""}`,
          data: {
            zone_name: data.name,
            zone_description: data.description,
            region_id: data.region,
            method: update ? "PUT" : "POST",
          },
        }).then(() => fetch("base_data/zone/"));
      } else if (active === 2) {
        return post({
          url: `/base_data/sector/${update ? editData.id + "/" : ""}`,
          data: {
            sector_name: data.name,
            sector_description: data.description,
            method: update ? "PUT" : "POST",
          },
        }).then(() => fetch("base_data/sector/"));
      } else if (active === 3) {
        return post({
          url: `/base_data/project_category/${update ? editData.id + "/" : ""}`,
          data: {
            project_category_name: data.name,
            project_category_description: data.description,
            method: update ? "PUT" : "POST",
          },
        }).then(() => fetch("base_data/project_category/"));
      }

      setEditData(undefined);
    } catch (error) {
      console.error(error);
      setEditData(undefined);
    }
  };

  const fetchActive = () => {
    if (active === 0) {
      fetch({ url: "/base_data/region/" });
    } else if (active === 1) {
      fetch({ url: "/base_data/zone/" });
    } else if (active === 2) {
      fetch({ url: "/base_data/sector/" });
    } else if (active === 3) {
      fetch({ url: "/base_data/project_category/" });
    }
  };

  useEffect(() => {
    setPage(1);
    fetchActive();
  }, [active]);

  useEffect(() => {
    fetchActive();
  }, [page]);

  return (
    <div className="mt-16">
      <BaseDataFormModal
        title={
          active === 0
            ? "Region"
            : active === 1
            ? "Zone"
            : active === 2
            ? "Sector"
            : "Project Category"
        }
        regions={data}
        isZone={active === 1}
        submit={handleSubmit}
        loading={postLoading || loading}
        editData={editData}
        setEditData={setEditData}
      />

      <div className="w-full items-start justify-between gap-5 lg:flex">
        {/* Left side */}
        <div className="flex flex-col gap-5 rounded-lg bg-[#F2F9FFCC] p-10 dark:bg-navy-800 dark:text-white lg:w-96 3xl:w-1/4">
          <div
            className={`flex w-[80%] cursor-pointer items-center gap-3 rounded-r-lg p-4 ${
              active === 0
                ? "border-l-4 border-[#485FE5] bg-[#5030E514] font-semibold text-[#485FE5]"
                : ""
            }`}
            onClick={() => setActive(0)}
          >
            <IoLocationOutline size={30} />
            <p>Region</p>
          </div>
          <div
            className={`flex w-[80%] cursor-pointer items-center gap-3 rounded-r-lg p-4 ${
              active === 1
                ? "border-l-4 border-[#485FE5] bg-[#5030E514] font-semibold text-[#485FE5]"
                : ""
            }`}
            onClick={() => setActive(1)}
          >
            <IoLocationOutline size={30} />
            <p>Zone</p>
          </div>
          <div
            className={`flex w-[80%] cursor-pointer items-end gap-3 rounded-r-lg p-4 ${
              active === 2
                ? "border-l-4 border-[#485FE5] bg-[#5030E514] fill-[#485FE5] font-semibold text-[#485FE5] "
                : "dark:fill-white"
            }`}
            onClick={() => setActive(2)}
          >
            <SectorIcon />

            <p>Sector</p>
          </div>
          <div
            className={`flex w-[80%] cursor-pointer items-end gap-3 rounded-r-lg p-4 ${
              active === 3
                ? "border-l-4 border-[#485FE5] bg-[#5030E514] fill-[#485FE5] font-semibold text-[#485FE5] "
                : "dark:fill-white"
            }`}
            onClick={() => setActive(3)}
          >
            <SectorIcon />
            <p>Project Category</p>
          </div>
        </div>

        {/* Right side */}
        <div className="mt-8 w-full rounded-lg lg:mt-0 lg:w-3/4 lg:px-10">
          {(error || postErr) && <Error error={error || postErr} />}
          <div className="flex items-center justify-end">
            <button
              className="rounded-lg bg-[#485FE5] px-8 py-2 text-white"
              onClick={() => {
                setEditData(undefined);
                window.document
                  .getElementById("BaseDataFormModal")
                  .classList.replace("hidden", "flex");
              }}
            >
              + Add
            </button>
          </div>
          {(loading || postLoading) && <Loading />}
          {!loading && !postLoading && (
            <div className="mt-5 w-full">
              {size?.width < 720 ? (
                <div className="grid grid-cols-1 gap-5">
                  {data?.results?.length > 0 &&
                    data?.results?.map((item, index) => (
                      <div
                        key={index}
                        className={`flex items-center justify-between rounded-lg bg-[#F2F9FFCC] p-5 dark:bg-navy-800 dark:text-white`}
                      >
                        <div>
                          <p className="font-semibold">
                            {item.region_name ||
                              item.zone_name ||
                              item.sector_name ||
                              item.project_category_name ||
                              item.name}
                          </p>
                          <p className="text-[#667085] dark:text-white">
                            {item.region_description ||
                              item.zone_description ||
                              item.sector_description ||
                              item.project_category_description}
                          </p>
                        </div>
                        <button
                          className="w-fit rounded-xl bg-[#485FE5] py-1.5 px-10 text-white"
                          onClick={() => {
                            setEditData(item);
                            window.document
                              .getElementById("BaseDataFormModal")
                              .classList.replace("hidden", "flex");
                          }}
                        >
                          Edit
                        </button>
                      </div>
                    ))}
                </div>
              ) : (
                <table className="w-full">
                  <thead className="bg-[#F2F9FFCC] dark:bg-navy-800 dark:text-white">
                    <tr>
                      <th className="py-5 px-5 text-left">ID</th>
                      <th className="text-left">Name</th>
                      {active === 1 && <th className="text-left">Region</th>}
                      {active !== 4 && (
                        <>
                          <th className="text-left">Description</th>
                          <th className="text-left">Created Date</th>
                        </>
                      )}

                      <th className="pl-5 text-left">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.results?.length > 0 &&
                      data?.results?.map((item, index) => (
                        <tr
                          className={`
                    ${
                      index % 2 === 0
                        ? "bg-white text-gray-800   dark:bg-navy-700"
                        : "bg-[#F2F9FFCC] dark:bg-navy-800 dark:text-white"
                    }
                  `}
                          key={index}
                        >
                          <td className="pl-4">{item.id}</td>
                          <td>
                            {item.region_name ||
                              item.zone_name ||
                              item.sector_name ||
                              item.project_category_name ||
                              item.name}
                          </td>
                          {active === 1 && <td>{item.region_name}</td>}
                          {active !== 4 && (
                            <>
                              <td>
                                <p className="w-fit max-w-[100px] truncate">
                                  {item.region_description ||
                                    item.zone_description ||
                                    item.sector_description ||
                                    item.project_category_description}
                                </p>
                              </td>
                              <td>
                                <p className="w-fit rounded-xl bg-[#F5F6F7] px-3 py-0.5 text-[#091E42] dark:bg-navy-900 dark:text-white">
                                  {item.created_date?.substring(0, 10)}
                                </p>
                              </td>
                            </>
                          )}

                          <td className="p-5">
                            <button
                              className="w-fit rounded-xl bg-[#485FE5] py-1.5 px-10 text-white"
                              onClick={() => {
                                setEditData(item);
                                window.document
                                  .getElementById("BaseDataFormModal")
                                  .classList.replace("hidden", "flex");
                              }}
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              <Paginate
                count={data?.count}
                page={page}
                setPage={setPage}
                next={() => {
                  if (!data?.next) {
                    return;
                  }
                  setPage(page + 1);
                }}
                previous={() => {
                  if (!data?.previous) {
                    return;
                  }
                  setPage(page - 1);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default BaseData;
