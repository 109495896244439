import Card from "components/card";
import BarChart from "components/charts/BarChart";

const ProjectsByR = ({ data }) => {
  return (
    <Card extra="flex flex-col bg-white shadow-md w-full rounded-3xl py-6 px-2 text-center bg-[#e6e6e659]/10">
      <div className="mb-auto flex items-center justify-between px-6">
        <h2 className="text-lg font-bold text-navy-700 dark:text-white">
          Projects by Region
        </h2>
      </div>

      <div className="md:mt-16 lg:mt-0">
        <div className="h-[250px] w-full xl:h-[350px]">
          {data && (
            <BarChart
              chartData={[
                {
                  data: data?.map((item) => ({
                    x: item.region,
                    y: item.count,
                  })),
                },
              ]}
              chartOptions={{
                chart: {
                  type: "bar",
                  toolbar: {
                    show: false,
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                legend: {
                  show: false,
                },
                plotOptions: {
                  bar: {
                    borderRadius: 5,
                    columnWidth: "20px",
                    distributed: true,
                  },
                },
              }}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProjectsByR;
