import React from "react";

function CalendarOutline() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_42640_5017)">
        <path
          d="M13 2.5H3C2.17157 2.5 1.5 3.17157 1.5 4V13C1.5 13.8284 2.17157 14.5 3 14.5H13C13.8284 14.5 14.5 13.8284 14.5 13V4C14.5 3.17157 13.8284 2.5 13 2.5Z"
          stroke="#828282"
          strokeWidth="1.2"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 5H1.5M4 1.5V2.5V1.5ZM12 1.5V2.5V1.5Z"
          stroke="#828282"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_42640_5017">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CalendarOutline;
