import React from "react";

function PendingProjects() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.53583 13.595L5 10.0583L6.17833 8.87996L8.53583 11.2366L13.2492 6.52246L14.4283 7.70163L8.53583 13.595Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833008 4.16634C0.833008 3.28229 1.1842 2.43444 1.80932 1.80932C2.43444 1.1842 3.28229 0.833008 4.16634 0.833008H15.833C16.7171 0.833008 17.5649 1.1842 18.19 1.80932C18.8152 2.43444 19.1663 3.28229 19.1663 4.16634V15.833C19.1663 16.7171 18.8152 17.5649 18.19 18.19C17.5649 18.8152 16.7171 19.1663 15.833 19.1663H4.16634C3.28229 19.1663 2.43444 18.8152 1.80932 18.19C1.1842 17.5649 0.833008 16.7171 0.833008 15.833V4.16634ZM4.16634 2.49967H15.833C16.275 2.49967 16.699 2.67527 17.0115 2.98783C17.3241 3.30039 17.4997 3.72431 17.4997 4.16634V15.833C17.4997 16.275 17.3241 16.699 17.0115 17.0115C16.699 17.3241 16.275 17.4997 15.833 17.4997H4.16634C3.72431 17.4997 3.30039 17.3241 2.98783 17.0115C2.67527 16.699 2.49967 16.275 2.49967 15.833V4.16634C2.49967 3.72431 2.67527 3.30039 2.98783 2.98783C3.30039 2.67527 3.72431 2.49967 4.16634 2.49967Z"
      />
    </svg>
  );
}

export default PendingProjects;
