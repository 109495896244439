function TasksIcon({ className }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="0.25"
        y="0.25"
        width="39.5"
        height="39.5"
        rx="7.75"
        strokeOpacity="0.2"
        strokeWidth="0.5"
      />
      <path
        d="M20.3398 17.1396H25.1523"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8477 17.1396L15.5352 17.8271L17.5977 15.7646"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3398 23.5566H25.1523"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8477 23.5566L15.5352 24.2441L17.5977 22.1816"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2487 29.1663H22.7487C27.332 29.1663 29.1654 27.333 29.1654 22.7497V17.2497C29.1654 12.6663 27.332 10.833 22.7487 10.833H17.2487C12.6654 10.833 10.832 12.6663 10.832 17.2497V22.7497C10.832 27.333 12.6654 29.1663 17.2487 29.1663Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default TasksIcon;
