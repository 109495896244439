/* eslint-disable react-hooks/exhaustive-deps */
import useAxios from "axios-hooks";
import Error from "components/error";
import Loading from "components/loading";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";

function BaseDataFormModal({
  title,
  isZone,
  submit,
  loading,
  editData,
  setEditData,
}) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [region, setRegion] = useState("");

  const [{ data: regions, loading: regionsLoad, error }, fetch] = useAxios(
    { url: "/base_data/region/" },
    { manual: true }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isZone) {
      await submit({ name, description, region }, editData ? true : false);
    } else {
      await submit({ name, description }, editData ? true : false);
    }

    window.document.getElementById("BaseDataFormModal").classList.add("hidden");
    clearForm();
  };

  useEffect(() => {
    if (isZone) {
      fetch();
    }
  }, [isZone]);

  const clearForm = () => {
    setName("");
    setDescription("");
    setRegion("");
  };

  useEffect(() => {
    if (editData) {
      let name =
        editData.region_name ||
        editData.zone_name ||
        editData.sector_name ||
        editData.project_category_name ||
        editData.name;
      setName(name);

      const desc =
        editData.region_description ||
        editData.zone_description ||
        editData.sector_description ||
        editData.project_category_description;

      setDescription(desc);

      if (isZone) {
        setRegion(editData.region_id);
      }
    } else {
      clearForm();
    }
  }, [editData]);

  return (
    <div
      id="BaseDataFormModal"
      tabIndex="-1"
      className="fixed inset-0 z-40 hidden w-full items-center justify-center overflow-y-scroll bg-gray-900/50 dark:bg-gray-900/80"
    >
      <div className="relative  w-[500px] rounded-lg bg-white">
        <button
          tabIndex="-1"
          className="absolute top-8 right-6"
          onClick={() => {
            setEditData(undefined);
            window.document
              .getElementById("BaseDataFormModal")
              .classList.add("hidden");
          }}
        >
          <FiX className="mx-auto h-6 w-6 text-red-600" />
        </button>
        <div className="mt-5 p-8">
          <h4 className="text-2xl font-bold text-gray-800 text-opacity-70 dark:text-gray-200">
            Add {title}
          </h4>
          <p className="text-[#667085]">
            Add <span className="lowercase">{title}</span> data in the form
            below
          </p>

          {loading || regionsLoad ? (
            <Loading />
          ) : (
            <form
              className="mt-5 rounded-lg border p-5"
              onSubmit={handleSubmit}
            >
              <label htmlFor="name" className="mb-5 text-[#667085]">
                {title} Name
              </label>
              {/* invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500 */}
              <input
                type="text"
                id="name"
                className="mt-3 w-full rounded-lg border p-4"
                placeholder={`write the ${title.toString().toLowerCase()} name`}
                required
                onChange={(e) => setName(e.target.value)}
                value={name}
              />

              {isZone && (
                <div className="mt-3">
                  {error && <Error error={error} />}
                  <label htmlFor="zone" className="text-[#667085]">
                    Select Region
                  </label>

                  <select
                    id="regionSelect"
                    className="mt-3 w-full rounded-lg border p-4 focus:outline-none"
                    disabled={regions?.results?.length === 0}
                    required
                    onChange={(e) => setRegion(e.target.value)}
                    value={region}
                  >
                    {regions?.results?.length === 0 && (
                      <option value="">No regions found</option>
                    )}
                    {regions?.results?.map((region) => (
                      <option key={region.id} value={region.id}>
                        {region.region_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {title !== "Role" && (
                <div className="mt-3">
                  <label htmlFor="zone" className="text-[#667085]">
                    Description
                  </label>
                  <textarea
                    id="description"
                    className="mt-3 w-full rounded-lg border p-4 focus:outline-none"
                    placeholder="write description"
                    rows={3}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </div>
              )}

              <button
                className="mt-5 w-full rounded-lg bg-[#485FE5] p-4 text-white"
                type="submit"
              >
                Save
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
export default BaseDataFormModal;
