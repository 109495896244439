import React from "react";

function ProjectOwnerIcon() {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 16 20"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.997 13.1748C3.684 13.1748 0 13.8548 0 16.5748C0 19.2958 3.661 19.9998 7.997 19.9998C12.31 19.9998 15.994 19.3208 15.994 16.5998C15.994 13.8788 12.334 13.1748 7.997 13.1748Z"
        fill="#485FE5"
      />
      <path
        opacity="0.4"
        d="M7.99708 10.584C10.9351 10.584 13.2891 8.229 13.2891 5.292C13.2891 2.355 10.9351 0 7.99708 0C5.06008 0 2.70508 2.355 2.70508 5.292C2.70508 8.229 5.06008 10.584 7.99708 10.584Z"
        fill="#485FE5"
      />
    </svg>
  );
}

export default ProjectOwnerIcon;
