import Dropdown from "components/dropdown";
import StatusDropdown from "components/dropdown/StatusDropdown";
import AddIconFilled from "components/icons/AddIconFilled";
import ChevronRightOutline from "components/icons/ChevronRightOutline";
import { useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import SubTaskForm from "views/admin/ceo/components/SubTaskForm";

function ActivityRowForm() {
  const [activityName, setActivityName] = useState("");
  const [activityStatus, setActivityStatus] = useState("Ongoing");
  const [activityExpert, setActivityExpert] = useState("");
  const [activityStart, setActivityStart] = useState("");
  const [activityEnd, setActivityEnd] = useState("");
  const [activityBudget, setActivityBudget] = useState("");

  const status = [
    { name: "Ongoing", color: "#5E5ADB", bg: "#EDEDFC" },
    { name: "Extended", color: "#C97A20", bg: "#FCF2E6" },
    { name: "Incomplete", color: "#D1293D", bg: "#FFEDEF" },
    { name: "Terminated", color: "#5A6376", bg: "#FFEDEF" },
  ];

  return (
    <tr className={`table-row `}>
      <td className="w-[40px]">
        <div className="ml-4 w-fit">
          <ChevronRightOutline />
        </div>
      </td>
      <td>
        <div className="ml-4 flex items-center gap-2">
          <p className="mx-2 text-lg dark:text-gray-800">1</p>
        </div>
      </td>
      <td className={`text-start`}>
        <input
          type="text"
          value={activityName}
          onChange={(e) => setActivityName(e.target.value)}
          className="w-full rounded-md border-gray-200 p-1 dark:border-navy-700 dark:text-navy-900"
          placeholder="Activity Name"
        />
      </td>
      <td>
        <div className="ml-3">
          <Dropdown
            button={<AddIconFilled className="h-8 w-8 cursor-pointer" />}
            classNames={"-left-36 top-8 w-max"}
            animation={"origin-top transition-all duration-300 ease-in-out"}
            children={<SubTaskForm />}
          />
        </div>
      </td>
      <td>
        <StatusDropdown
          pos={"-left-32"}
          button={
            <div className="flex items-center gap-2">
              <div
                className={`my-2 ml-3 flex w-fit items-center gap-2 rounded-lg p-1
                  bg-[${status.find((item) => item.name === activityStatus).bg}]
                `}
              >
                <div
                  className={`ml-1 h-2 w-2 rounded-md bg-[${
                    status.find((item) => item.name === activityStatus).color
                  }]`}
                />
                <p
                  className={`mr-2 text-[${
                    status.find((item) => item.name === activityStatus).color
                  }]`}
                >
                  {activityStatus}
                </p>
              </div>
              <FaChevronDown size={10} className="cursor-pointer" />
            </div>
          }
          onSubmit={(value) => setActivityStatus(value)}
        />
      </td>
      <td>
        <div className="mx-2 flex items-center gap-2">
          <img
            src={"https://api.dicebear.com/7.x/pixel-art/svg"}
            alt="user"
            className="h-6 w-6 rounded-2xl"
          />
          <input
            type="text"
            value={activityExpert}
            onChange={(e) => setActivityExpert(e.target.value)}
            className="w-full rounded-md  border-gray-200 p-1 dark:border-navy-700 dark:text-navy-900"
            placeholder="John Doe"
          />
        </div>
      </td>
      <td>
        <div className="flex w-fit items-center justify-center gap-1">
          <input
            type="date"
            value={activityStart}
            onChange={(e) => setActivityStart(e.target.value)}
            className=" rounded-md border-gray-200 p-1 dark:border-navy-700 dark:text-navy-900"
            placeholder="Start Date"
          />
          <BsChevronRight size={10} />
          <input
            type="date"
            value={activityEnd}
            onChange={(e) => setActivityEnd(e.target.value)}
            className="rounded-md border-gray-200 p-1 dark:border-navy-700 dark:text-navy-900"
            placeholder="End Date"
          />
        </div>
      </td>
      <td>
        <input
          type="text"
          value={activityBudget}
          onChange={(e) => setActivityBudget(e.target.value)}
          className="mx-2 w-full rounded-md border-gray-200 p-1 dark:border-navy-700 dark:text-navy-900"
          placeholder="$1000"
        />
      </td>
    </tr>
  );
}

export default ActivityRowForm;
