function ProjectTextInput({
  title,
  value,
  onChange,
  required = true,
  placeholder,
  type = "text",
  textArea = false,
  rows = 5,
}) {
  return (
    <div
      className={`my-4 flex justify-between ${
        textArea || title.includes("Contract")
          ? "flex-col lg:flex-row"
          : "flex-row items-center lg:items-start"
      }`}
    >
      <p className="flex-grow pb-2 lg:pb-0">
        {title} {required && <span className="text-red-700">*</span>}
      </p>
      {textArea ? (
        <textarea
          className="rounded-lg bg-[#FAFAFA] p-4 lg:w-[64%]"
          rows={rows}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          required={required}
        />
      ) : (
        <input
          type={type}
          className="flex-grow rounded-lg bg-[#FAFAFA] p-4 lg:w-[64%] lg:flex-grow-0"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
        />
      )}
    </div>
  );
}
export default ProjectTextInput;
