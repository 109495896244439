/* eslint-disable react-hooks/exhaustive-deps */
import useAxios from "axios-hooks";
import Error from "components/error";
import Loading from "components/loading";
import { useEffect } from "react";
import { FiX } from "react-icons/fi";
import { Link } from "react-router-dom";

function ProjectsModal({ id, link, showModal, setShowModal }) {
  const [{ data: projects, loading, error }, fetch] = useAxios(
    {
      url: "/main_app/projects/",
      params: { no_pagination: true },
      method: "GET",
    },
    { useCache: false, manual: true }
  );

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
      fetch();
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal]);

  return (
    <div
      id={id || "ProjectsModal"}
      tabIndex="-1"
      className={`fixed top-0 left-0 h-full w-full items-center justify-center overflow-y-hidden bg-gray-900/50 dark:bg-gray-900/80 ${
        showModal ? "flex" : "hidden"
      }`}
      style={{ zIndex: 9999 }}
    >
      <div className="relative w-full rounded-lg bg-white dark:bg-navy-800 md:w-[800px]">
        <button
          tabIndex="-1"
          className="absolute top-8 right-10"
          onClick={() => {
            setShowModal(false);
            document.body.style.overflow = "auto";
          }}
        >
          <FiX className="mx-auto h-6 w-6 text-red-600" />
        </button>
        <div className="mt-5 max-h-[800px] overflow-auto py-10 px-16 text-center">
          <h4 className="text-3xl font-bold text-opacity-70 dark:text-gray-200 md:text-4xl">
            Projects List
          </h4>
          {error && <Error error={error} />}
          {loading && <Loading />}
          {!loading && (
            <div className="mt-8 grid grid-cols-1 gap-5 font-poppins">
              {projects &&
                projects?.map((project) => (
                  <div
                    key={project.id}
                    className="flex items-end justify-between border-b border-gray-200 p-3 dark:border-navy-700"
                  >
                    <div className="flex items-center gap-4">
                      <div>
                        <h1 className="text-start text-lg font-semibold text-gray-800 dark:text-gray-200">
                          {project.project_name}
                        </h1>
                      </div>
                    </div>
                    <div>
                      <Link
                        to={`/${link}/projects/${project.id}/dashboard`}
                        className="text-sm font-semibold text-blue-600 hover:underline"
                        onClick={() => {
                          setShowModal(false);
                          document.body.style.overflow = "auto";
                        }}
                      >
                        View
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ProjectsModal;
