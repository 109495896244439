import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (credentials) => ({
        url: "/api/v1/users/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...credentials }),
      }),
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: "/api/v1/jwt/create/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...credentials }),
      }),
    }),
    verifyEmail: builder.mutation({
      query: (credentials) => ({
        url: `/api/v1/users/activation/`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...credentials }),
      }),
    }),
    profileCreate: builder.mutation({
      query: (data) => ({
        url: `/account/profile/`,
        method: "POST",
        body: data,
      }),
    }),
    profileUpdate: builder.mutation({
      query: (data) => ({
        url: `/account/profile/update-profile/`,
        method: "PUT",
        body: data,
      }),
    }),
    userProfile: builder.mutation({
      query: () => ({
        url: `/account/profile/`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignupMutation,
  useVerifyEmailMutation,
  useProfileUpdateMutation,
  useProfileCreateMutation,
  useUserProfileMutation,
} = authApiSlice;
