import React from "react";

function StarHalfIcon() {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M10.9763 1.11361L13.2028 5.58789C13.3668 5.91205 13.6799 6.13717 14.041 6.18719L19.042 6.91556C19.3341 6.95658 19.5992 7.11066 19.7782 7.34578C19.9552 7.5779 20.0312 7.87205 19.9882 8.1612C19.9532 8.40132 19.8402 8.62343 19.6672 8.79352L16.0434 12.3063C15.7783 12.5514 15.6583 12.9146 15.7223 13.2698L16.6145 18.2083C16.7095 18.8046 16.3144 19.3669 15.7223 19.48C15.4783 19.519 15.2282 19.478 15.0082 19.3659L10.5472 17.0417C10.2161 16.8746 9.82505 16.8746 9.49398 17.0417L5.03303 19.3659C4.48491 19.657 3.80576 19.4589 3.5007 18.9187C3.38767 18.7036 3.34766 18.4584 3.38467 18.2193L4.27686 13.2798C4.34088 12.9256 4.21985 12.5604 3.95579 12.3153L0.332017 8.80452C-0.0990758 8.38831 -0.112079 7.70296 0.30301 7.27175C0.312012 7.26274 0.322015 7.25274 0.332017 7.24273C0.504054 7.06764 0.730102 6.95658 0.974155 6.92757L5.97523 6.1982C6.33531 6.14717 6.64837 5.92406 6.81341 5.59789L8.95987 1.11361C9.15091 0.729419 9.547 0.490297 9.97709 0.500302H10.1111C10.4842 0.545325 10.8093 0.776443 10.9763 1.11361Z"
        fill="#D10202"
      />
      <path
        d="M9.99201 16.9171C9.79831 16.9231 9.6096 16.9752 9.43987 17.0682L5.00072 19.3871C4.45756 19.6464 3.80756 19.4452 3.50303 18.9258C3.39021 18.7136 3.34927 18.4704 3.38721 18.2322L4.27384 13.3032C4.33375 12.9449 4.21394 12.5806 3.95334 12.3284L0.327937 8.81848C-0.102398 8.39713 -0.110386 7.70556 0.310963 7.27421C0.316954 7.26821 0.321947 7.2632 0.327937 7.2582C0.499672 7.08806 0.72133 6.97597 0.959961 6.94094L5.96523 6.20433C6.32767 6.1583 6.64219 5.93211 6.80194 5.60384L8.97758 1.06312C9.18426 0.696818 9.58065 0.478639 10 0.501658C9.99201 0.798902 9.99201 16.715 9.99201 16.9171Z"
        fill="#D10202"
      />
    </svg>
  );
}

export default StarHalfIcon;
