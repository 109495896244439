import InputField from "components/fields/InputField";
import logoFull from "assets/img/layout/MoLS-Logo-Full.png";
import { Link } from "react-router-dom";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import { useState } from "react";
import MailSent from "components/icons/MailSent";

function PasswordReset() {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [{ loading, error }, resetPassword] = useAxios(
    {
      url: "/api/v1/users/reset_password/",
      method: "POST",
    },
    { manual: true }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      resetPassword({ data: { email } }).then((res) => {
        setSent(true);
      });
    } catch (error) {}
  };

  const SentUI = () => (
    <div className="h-full w-full bg-[#F0F2FF] text-center font-poppins text-[#485FE5]">
      <div className="absolute top-64 min-h-[300px] w-[550px] rounded-md bg-white md:left-[35%]">
        <div className="absolute -top-20 left-52 w-fit rounded-full bg-[#F0F2FF] p-4">
          <div className="w-fit rounded-full bg-white p-10">
            <MailSent />
          </div>
        </div>

        <div className="mx-auto mt-24 max-w-sm">
          <h1 className="my-4 text-2xl font-bold">Please Check your email</h1>
          <p className="text-lg font-semibold">
            We sent an email to <span className="underline">{email}</span>
          </p>
          <p className="my-5 mx-2">
            Just click on the link in that email to complete your password
            reset. If you don't see it, you may need to check your spam folder.
          </p>
          <p
            className={`mx-auto mb-5 w-fit cursor-pointer pb-6 font-semibold underline`}
            onClick={() => setSent(false)}
          >
            Wrong email?
          </p>
        </div>
      </div>
    </div>
  );

  const FormUI = () => (
    <div className="h-screen w-screen bg-[#F0F2FF]">
      {error && <Error error={error} />}
      {loading && <Loading />}
      {!loading && !error && (
        <div className="mx-auto h-full w-fit rounded-lg p-6 pt-56 text-center font-poppins  xl:pt-[15%]">
          <img src={logoFull} alt="logo" className="mx-auto mb-6 h-14 w-60" />
          <h2 className="mb-10 text-xl font-semibold text-gray-900">
            Forgot Password
          </h2>

          <p className="mb-5 max-w-md text-start text-navy-600">
            Enter the email address associated with your account and we'll send
            you a link to reset your password.
          </p>
          <form onSubmit={handleSubmit}>
            <InputField
              variant="auth"
              extra="mb-3 text-start"
              label="Email"
              placeholder="example@gmail.com"
              id="email"
              type="text"
              required
              value={email}
              onChange={setEmail}
            />
            <button
              type="submit"
              className="w-full rounded-lg bg-blue-500 py-3 px-4 font-bold text-white hover:bg-blue-700"
            >
              Send
            </button>
          </form>

          <div className="mt-8">
            Not registered yet?{" "}
            <Link
              to="/auth/sign-up"
              className="mt-5 text-sm font-bold text-blue-700 dark:text-white"
            >
              Create an Account
            </Link>
          </div>
        </div>
      )}
    </div>
  );

  return sent ? <SentUI /> : <FormUI />;
}
export default PasswordReset;
