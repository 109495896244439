import React from "react";

function BarsFilled() {
  return (
    <svg
      width="54"
      height="50"
      viewBox="0 0 54 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="47.8228"
        y="49.2236"
        width="49.2205"
        height="5.76"
        rx="2.88"
        transform="rotate(-90 47.8228 49.2236)"
        fill="#E9EDF7"
      />
      <rect
        x="47.4688"
        y="49.1855"
        width="49.183"
        height="5.78624"
        rx="2.89312"
        transform="rotate(-90 47.4688 49.1855)"
        fill="#52A57E"
      />
      <rect
        x="32.1401"
        y="49.2236"
        width="49.2205"
        height="5.75995"
        rx="2.87998"
        transform="rotate(-90 32.1401 49.2236)"
        fill="#E9EDF7"
      />
      <rect
        x="32.0386"
        y="49.1855"
        width="49.183"
        height="5.78624"
        rx="2.89312"
        transform="rotate(-90 32.0386 49.1855)"
        fill="#52A57E"
      />
      <rect
        x="16.4604"
        y="49.2236"
        width="49.2205"
        height="5.76001"
        rx="2.88"
        transform="rotate(-90 16.4604 49.2236)"
        fill="#E9EDF7"
      />
      <rect
        x="16.6094"
        y="49.1855"
        width="49.183"
        height="5.78624"
        rx="2.89312"
        transform="rotate(-90 16.6094 49.1855)"
        fill="#52A57E"
      />
      <rect
        x="0.779297"
        y="49.2236"
        width="49.2205"
        height="5.76002"
        rx="2.88001"
        transform="rotate(-90 0.779297 49.2236)"
        fill="#E9EDF7"
      />
      <rect
        x="1.17871"
        y="49.1855"
        width="49.183"
        height="5.78624"
        rx="2.89312"
        transform="rotate(-90 1.17871 49.1855)"
        fill="#52A57E"
      />
    </svg>
  );
}

export default BarsFilled;
