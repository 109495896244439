/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { FiX } from "react-icons/fi";
import useAxios from "axios-hooks";
import html2pdf from "html2pdf.js";

function ProjectReport({ project, id, showModal, setShowModal }) {
  const pdfExportComponent = useRef(null);
  const [expert, setExpert] = useState(null);
  const [{ data: users }, fetchUsers] = useAxios(
    {
      url: "/account/user_list?no_pagination=true",
      method: "GET",
    },
    { manual: true }
  );

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      const opt = {
        margin: [0, 0.5, 0, 0.5],
        filename: `Report for ${
          project.project_name
        } ${new Date().toLocaleDateString()}`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          scale: 2,
          useCORS: true,
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        pagebreak: { mode: ["css", "legacy"], before: "#page2el" },
      };
      html2pdf()
        .set(opt)
        .from(document.getElementById(`pdfExport${id}`))
        .save();
    }
  };

  const getAllActivities = () => {
    let activities = [];
    project?.milestones?.results?.forEach((milestone) => {
      activities = [...activities, ...milestone.activities];
    });
    return activities;
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
      if (project) {
        fetchUsers();
      }
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal, project]);

  useEffect(() => {
    if (users) {
      setExpert(
        users?.find((user) => user.profile?.user === project?.assigned_to)
      );
    }
  }, [users]);

  return (
    <div
      id={id || "ProjectsModal"}
      tabIndex="-1"
      className={`fixed top-0 left-0 h-full w-full items-center justify-center overflow-y-hidden bg-gray-900/50 dark:bg-gray-900/80 ${
        showModal ? "flex" : "hidden"
      }`}
      style={{ zIndex: 9999 }}
    >
      <div className="relative h-full w-full overflow-auto rounded-lg bg-white p-5 dark:bg-navy-800 md:w-[800px]">
        <div className="flex flex-row-reverse justify-between">
          <button
            tabIndex="-1"
            className=""
            onClick={() => {
              setShowModal(false);
              document.body.style.overflow = "auto";
            }}
          >
            <FiX className="mx-auto h-6 w-6 text-red-600" />
          </button>

          <button
            onClick={exportPDFWithComponent}
            className="rounded-lg bg-blue-500 px-4 py-2 text-white"
          >
            Export PDF
          </button>
        </div>

        {project && (
          <div id={`pdfExport${id}`} ref={pdfExportComponent} className="py-10">
            <div className="mx-28 mt-[15%]">
              <h1 className=" text-start text-6xl font-bold capitalize text-[#485FE5]">
                {project.project_name}
              </h1>
              <p className="mt-10 font-serif text-xl">{project?.short_name}</p>
              <p className="mt-16 text-lg">Prepared By:</p>
              {expert ? (
                <p className="mt-2 text-base">
                  {expert?.profile?.first_name} {expert?.profile?.last_name}
                </p>
              ) : (
                <p className="mt-2 text-base">
                  No Expert was assigned to this project
                </p>
              )}
              <p className="mt-2 text-base">{expert?.roles[0]?.role?.name}</p>
              <p className="mt-2 text-base">{expert?.email}</p>
            </div>

            <div className="page-break html2pdf__page-break"></div>

            <div className="mx-10 ">
              <h1
                className="mt-10 max-w-[80%] text-xl"
                style={{ fontWeight: "bold" }}
              >
                Project Description
              </h1>
              <p className="mt-1">{project.project_description}</p>

              <h1
                className="mt-10 max-w-[80%] text-xl"
                style={{ fontWeight: "bold" }}
              >
                Project Objective
              </h1>
              <p className="mt-1">{project.project_objective}</p>

              <h1
                className="mt-10 max-w-[80%] text-xl"
                style={{ fontWeight: "bold" }}
              >
                Project Goal
              </h1>
              <p className="mt-1">{project.project_goal}</p>

              <h1
                className="mt-10 max-w-[80%] text-xl"
                style={{ fontWeight: "bold" }}
              >
                Project Location
              </h1>
              <p className="mt-1">
                This project is based in {project?.region_name},{" "}
                {project?.zone_name}, {project?.sector_name}
              </p>

              <h1
                className="mt-10 max-w-[80%] text-xl"
                style={{ fontWeight: "bold" }}
              >
                Project Budget
              </h1>
              <p className="mt-1">
                This project's Estimated Budget is{" "}
                {Number(project?.project_cost).toFixed()}
              </p>

              <h1
                className="mt-10 max-w-[80%] text-xl"
                style={{ fontWeight: "bold" }}
              >
                Project Timeline
              </h1>
              <p className="mt-1">
                This project's estimated timeline is from{" "}
                {new Date(project?.start_date).toDateString()}
                {" to "} {new Date(project?.end_date).toDateString()}
              </p>

              <h1
                className="mt-10 max-w-[80%] text-xl"
                style={{ fontWeight: "bold" }}
              >
                Project Status
              </h1>
              <p className="mt-1">
                This project is currently {project?.status}
              </p>

              <h1
                className="mt-10 max-w-[80%] text-xl"
                style={{ fontWeight: "bold" }}
              >
                Project Beneficiaries
              </h1>
              <p className="mt-1">{project.project_beneficiaries}</p>

              <h1
                className="mt-10 max-w-[80%] text-xl"
                style={{ fontWeight: "bold" }}
              >
                Project Rescources
              </h1>
              <p className="mt-1">{project.resources}</p>

              <h1
                className="mt-10 max-w-[80%] text-xl"
                style={{ fontWeight: "bold" }}
              >
                Milestones
              </h1>
              {project.milestones?.results?.length > 0 && (
                <table className="mt-5 table table-auto text-sm">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Milestone</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {project?.milestones?.results?.map((milestone, index) => (
                      <tr key={milestone.id} className="text-center">
                        <td>{index + 1}</td>
                        <td className="px-5 py-2 text-sm">
                          {milestone.milestone_name}
                        </td>
                        <td className="px-5 py-2">
                          {new Date(milestone.start_date).toDateString()}
                        </td>
                        <td className="px-5 py-2">
                          {new Date(milestone.end_date).toDateString()}
                        </td>
                        <td className="px-5 py-2">{milestone.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              <h1
                className="mt-10 max-w-[80%] text-xl"
                style={{ fontWeight: "bold" }}
              >
                Activities
              </h1>
              {getAllActivities()?.length > 0 && (
                <table className="mt-5 table table-auto text-sm">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Activity</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getAllActivities()?.map((activity, index) => (
                      <tr key={activity.id}>
                        <td>{index + 1}</td>
                        <td className="px-5 py-2 text-center text-sm">
                          {activity.activity_name}
                        </td>
                        <td className="px-5 py-2">
                          {new Date(activity.start_date).toDateString()}
                        </td>
                        <td className="px-5 py-2">
                          {new Date(activity.end_date).toDateString()}
                        </td>
                        <td className="px-5 py-2">{activity.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ProjectReport;
