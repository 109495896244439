function UserIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="stroke-[#787486] dark:stroke-white/80"
    >
      <rect
        x="0.25"
        y="0.25"
        width="39.5"
        height="39.5"
        rx="7.75"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="0.5"
      />
      <path
        d="M20 18C22.2091 18 24 16.2091 24 14C24 11.7909 22.2091 10 20 10C17.7909 10 16 11.7909 16 14C16 16.2091 17.7909 18 20 18Z"
        strokeWidth="1.5"
      />
      <path
        d="M28 25.5C28 27.985 28 30 20 30C12 30 12 27.985 12 25.5C12 23.015 15.582 21 20 21C24.418 21 28 23.015 28 25.5Z"
        strokeWidth="1.5"
      />
    </svg>
  );
}
export default UserIcon;
