export function getRandomColor() {
  var allowed = "ABCDEF0123456789",
    S = "#";

  while (S.length < 7) {
    S += allowed.charAt(Math.floor(Math.random() * 16 + 1));
  }
  return S;
}

export const stringifyError = (error) => {
  return JSON.stringify(error.response.data, null, 2).replace(
    /[[\]{}"",]+/g,
    " "
  );
};
