import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    accessToken: localStorage.getItem("token") ?? null,
    refreshToken: localStorage.getItem("refreshToken") ?? null,
    email: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).email
      : null,
    password: null,
    firstName: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).first_name
      : null,
    lastName: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).last_name
      : null,
    profilePic: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).file
      : null,
    bio: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).bio
      : null,
    id: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).id
      : null,
    role: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).roles[0]?.role
      : null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { email, accessToken, refreshToken } = action.payload;
      state.email = email;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      localStorage.setItem("token", accessToken.toString());
      localStorage.setItem("refreshToken", refreshToken.toString());
    },
    setProfile: (state, action) => {
      const { first_name, last_name, file, user, bio, roles } = action.payload;
      state.id = user;
      state.firstName = first_name;
      state.lastName = last_name;
      state.profilePic = file;
      state.bio = bio;
      state.role = roles[0]?.role;
      localStorage.setItem(
        "user",
        JSON.stringify({ ...action.payload, email: state.email })
      );
    },
    logOut: (state, action) => {
      state.email = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.firstName = null;
      state.lastName = null;
      state.profilePic = null;
      state.bio = null;
      state.id = null;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("refreshToken");
    },
  },
});

export const { setCredentials, setProfile, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentEmail = (state) => state.auth.email;
export const selectCurrentToken = (state) => state.auth.accessToken;
export const selectCurrentRefreshToken = (state) => state.auth.refreshToken;
export const selectCurrentFirstName = (state) => state.auth.firstName;
export const selectCurrentLastName = (state) => state.auth.lastName;
export const selectCurrentProfilePic = (state) => state.auth.profilePic;
