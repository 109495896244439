import AllProjects from "components/icons/AllProjects";
import CompletedProjects from "components/icons/CompletedProjects";
import PendingProjects from "components/icons/PendingProjects";
import Paginate from "components/pagination";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ComplexTableAssigned from "views/admin/team-leader/components/ComplexTableAssigned";

const columnsDataComplex = [
  {
    Header: "No",
    accessor: "id",
  },
  {
    Header: "Project Name",
    accessor: "project_name",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
  },
  {
    Header: "End Date",
    accessor: "end_date",
  },

  {
    Header: "Priority",
    accessor: "project_priority",
  },
  {
    Header: "Region",
    accessor: "region_name",
  },

  {
    Header: "Project Plan Status",
    accessor: "execution_plan_status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

function AssignedProjectsList({ projects, page, setPage }) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    { title: "All Project Lists", icon: <AllProjects /> },
    {
      title: "Approved",
      icon: <PendingProjects />,
    },
    { title: "Rejected", icon: <CompletedProjects /> },
  ];

  const tableData = projects?.results
    ?.filter((item) => {
      if (activeTab === 0) {
        return item;
      }
      if (activeTab === 1) {
        return item["execution_plan_status"] === "approved";
      }
      if (activeTab === 2) {
        return item["execution_plan_status"] === "rejected";
      }

      return true;
    })
    .map((item, index) => {
      if (activeTab === 0) {
        const newItem = { ...item, action: 0 };
        return newItem;
      }
      item["action"] = 1;
      return item;
    });

  return (
    <div className="mt-5 rounded-lg bg-[#F5FAFF] p-5 px-10 dark:bg-navy-700">
      {/* Add project drawer */}
      <div className="mb-3 flex w-fit items-center justify-between">
        <div className="my-3 w-fit border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-navy-700 dark:text-gray-400">
          <ul className="-mb-px w-fit flex-wrap gap-5 lg:flex lg:gap-16">
            {tabs.map((item, index) => (
              <li className="flex w-fit items-center " key={index}>
                <p
                  className={`flex w-fit cursor-pointer items-center gap-3 rounded-t-lg border-b-2 p-4  hover:border-navy-300  hover:text-gray-600 dark:hover:text-gray-300 ${
                    activeTab === index
                      ? "border-blue-600 fill-blue-600 text-blue-600 dark:border-navy-500 dark:text-blue-500"
                      : "border-none fill-[#84818A]"
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {item.icon}
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {projects?.results?.length > 0 && (
        <>
          <ComplexTableAssigned
            columnsData={columnsDataComplex}
            tableData={tableData}
          />
          <Paginate
            count={projects?.count}
            page={page}
            setPage={(p) => {
              navigate(`/team-leader/projects?page=${p}`);
              setPage(p);
            }}
            next={() => {
              if (!projects?.next) {
                return;
              }
              navigate(`/team-leader/projects?page=${page + 1}`);
              setPage(page + 1);
            }}
            previous={() => {
              if (!projects?.previous) {
                return;
              }
              navigate(`/team-leader/projects?page=${page - 1}`);
              setPage(page - 1);
            }}
          />
        </>
      )}
    </div>
  );
}

export default AssignedProjectsList;
