import React from "react";

function CategoryIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.83854 0.576172H1.25205C0.960905 0.576172 0.681688 0.691827 0.47582 0.897695C0.269952 1.10356 0.154297 1.38278 0.154297 1.67392V8.26042C0.154297 8.55156 0.269952 8.83077 0.47582 9.03664C0.681688 9.24251 0.960905 9.35817 1.25205 9.35817H7.83854C8.12968 9.35817 8.4089 9.24251 8.61477 9.03664C8.82063 8.83077 8.93629 8.55156 8.93629 8.26042V1.67392C8.93629 1.38278 8.82063 1.10356 8.61477 0.897695C8.4089 0.691827 8.12968 0.576172 7.83854 0.576172ZM6.74079 7.16267H2.3498V2.77167H6.74079V7.16267ZM18.816 0.576172H12.2295C11.9384 0.576172 11.6592 0.691827 11.4533 0.897695C11.2474 1.10356 11.1318 1.38278 11.1318 1.67392V8.26042C11.1318 8.55156 11.2474 8.83077 11.4533 9.03664C11.6592 9.24251 11.9384 9.35817 12.2295 9.35817H18.816C19.1072 9.35817 19.3864 9.24251 19.5923 9.03664C19.7981 8.83077 19.9138 8.55156 19.9138 8.26042V1.67392C19.9138 1.38278 19.7981 1.10356 19.5923 0.897695C19.3864 0.691827 19.1072 0.576172 18.816 0.576172ZM17.7183 7.16267H13.3273V2.77167H17.7183V7.16267ZM7.83854 11.5537H1.25205C0.960905 11.5537 0.681688 11.6693 0.47582 11.8752C0.269952 12.0811 0.154297 12.3603 0.154297 12.6514V19.2379C0.154297 19.5291 0.269952 19.8083 0.47582 20.0141C0.681688 20.22 0.960905 20.3357 1.25205 20.3357H7.83854C8.12968 20.3357 8.4089 20.22 8.61477 20.0141C8.82063 19.8083 8.93629 19.5291 8.93629 19.2379V12.6514C8.93629 12.3603 8.82063 12.0811 8.61477 11.8752C8.4089 11.6693 8.12968 11.5537 7.83854 11.5537ZM6.74079 18.1402H2.3498V13.7492H6.74079V18.1402ZM15.5228 11.5537C13.1012 11.5537 11.1318 13.523 11.1318 15.9447C11.1318 18.3663 13.1012 20.3357 15.5228 20.3357C17.9444 20.3357 19.9138 18.3663 19.9138 15.9447C19.9138 13.523 17.9444 11.5537 15.5228 11.5537ZM15.5228 18.1402C14.312 18.1402 13.3273 17.1555 13.3273 15.9447C13.3273 14.7338 14.312 13.7492 15.5228 13.7492C16.7336 13.7492 17.7183 14.7338 17.7183 15.9447C17.7183 17.1555 16.7336 18.1402 15.5228 18.1402Z"
        fill="white"
      />
    </svg>
  );
}

export default CategoryIcon;
