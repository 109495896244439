import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import avatar from "assets/img/avatars/dummy.png";

const ComplexTable = (props) => {
  const { columnsData, tableData, setUser } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  return (
    <Card
      extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto bg-[#FAFAFA80] shadow"}
    >
      <div className="mt-8 overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 pr-4 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <p className="text-xs tracking-wide text-gray-600">
                      {column.render("Header") !== "ACTION"
                        ? column.render("Header")
                        : " "}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "NO") {
                      data = (
                        <p className="w-fit font-semibold text-[#787486] dark:text-white">
                          {Number(i + 1).toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                          })}
                        </p>
                      );
                    } else if (cell.column.Header === "First Name") {
                      data = (
                        <div className="flex w-fit items-center gap-2">
                          <img
                            src={cell.row.original.profile?.file || avatar}
                            alt="user"
                            className="h-9 w-9 rounded-full"
                          />
                          <div className="w-[250px] lg:w-fit">
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value || "N/A"}
                            </p>
                            <p className="text-xs text-[#6B788E] dark:text-gray-400">
                              {cell.row.original.email}
                            </p>
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === "STATUS") {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className={`rounded-full text-xl`}>
                            {cell.value === true ? (
                              <div className="flex min-w-[90px] items-center gap-2 rounded-xl bg-[#ECFDF3] py-0.5 px-3">
                                <div className="h-2 w-2 rounded-lg bg-[#12B76A]" />
                                <p className="text-sm font-bold text-[#027A48]">
                                  Active
                                </p>
                              </div>
                            ) : cell.value === false ? (
                              <div className="flex min-w-[90px] items-center gap-2 rounded-xl bg-[#FFF2EA] py-0.5 px-3">
                                <div className="h-2 w-2 rounded-lg bg-[#F15046]" />
                                <p className="text-sm font-bold text-[#F15046]">
                                  Inactive
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === "ROLE") {
                      data = (
                        <p className="pr-6 text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "Last Name") {
                      data = (
                        <p className="pr-10 text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value || "N/A"}
                        </p>
                      );
                    } else if (cell.column.Header === "ACTION") {
                      data = (
                        <BsThreeDotsVertical
                          className={`${
                            !cell.row.original.profile
                              ? "text-gray-400"
                              : "cursor-pointer"
                          }`}
                          onClick={() => {
                            if (
                              cell.row.original.is_active &&
                              cell.row.original.profile
                            ) {
                              setUser(cell.row.original);
                              return;
                            }

                            return null;
                          }}
                        />
                      );
                    }
                    return (
                      <td
                        className="pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ComplexTable;
