import React from "react";

function CompletedProjects() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_42039_17206)">
        <path d="M7.4333 7.0583C7.55049 6.94126 7.70934 6.87552 7.87497 6.87552C8.04059 6.87552 8.19944 6.94126 8.31663 7.0583L10.375 9.11663L12.4333 7.0583C12.4905 6.99689 12.5595 6.94764 12.6362 6.91348C12.7129 6.87932 12.7956 6.86095 12.8795 6.85947C12.9634 6.85799 13.0468 6.87343 13.1246 6.90486C13.2025 6.9363 13.2731 6.98308 13.3325 7.04243C13.3918 7.10178 13.4386 7.17248 13.4701 7.2503C13.5015 7.32812 13.5169 7.41148 13.5155 7.4954C13.514 7.57932 13.4956 7.66208 13.4615 7.73875C13.4273 7.81541 13.378 7.88441 13.3166 7.94163L11.2583 9.99997L13.3166 12.0583C13.427 12.1768 13.4871 12.3335 13.4843 12.4954C13.4814 12.6573 13.4158 12.8118 13.3013 12.9263C13.1868 13.0408 13.0323 13.1064 12.8704 13.1093C12.7085 13.1121 12.5518 13.052 12.4333 12.9416L10.375 10.8833L8.31663 12.9416C8.25941 13.003 8.19041 13.0523 8.11375 13.0865C8.03708 13.1206 7.95432 13.139 7.8704 13.1405C7.78648 13.1419 7.70312 13.1265 7.6253 13.0951C7.54748 13.0636 7.47678 13.0168 7.41743 12.9575C7.35808 12.8981 7.3113 12.8275 7.27986 12.7496C7.24843 12.6718 7.23299 12.5884 7.23447 12.5045C7.23595 12.4206 7.25432 12.3379 7.28848 12.2612C7.32264 12.1845 7.37189 12.1155 7.4333 12.0583L9.49163 9.99997L7.4333 7.94163C7.31626 7.82444 7.25052 7.66559 7.25052 7.49997C7.25052 7.33434 7.31626 7.17549 7.4333 7.0583Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.66228 0.304797C8.33428 -0.101599 12.04 -0.101599 15.712 0.304797C17.8675 0.545072 19.6076 2.23995 19.8601 4.4036C20.2966 8.12196 20.2966 11.878 19.8601 15.5964C19.6065 17.76 17.8663 19.4537 15.712 19.6952C12.04 20.1016 8.33428 20.1016 4.66228 19.6952C2.50683 19.4537 0.76667 17.76 0.514199 15.5964C0.0786005 11.8781 0.0786005 8.12186 0.514199 4.4036C0.76667 2.23995 2.50801 0.545072 4.66228 0.304797ZM15.5162 2.05974C11.9743 1.66781 8.39997 1.66781 4.85812 2.05974C4.20245 2.13236 3.59046 2.42354 3.121 2.88624C2.65154 3.34893 2.35204 3.95612 2.27088 4.60972C1.85129 8.19142 1.85129 11.8097 2.27088 15.3914C2.35229 16.0448 2.6519 16.6517 3.12133 17.1142C3.59077 17.5767 4.20263 17.8677 4.85812 17.9402C8.37031 18.3313 12.004 18.3313 15.5162 17.9402C16.1715 17.8674 16.7831 17.5763 17.2523 17.1139C17.7215 16.6514 18.0209 16.0446 18.1023 15.3914C18.5219 11.8097 18.5219 8.19142 18.1023 4.60972C18.0207 3.95673 17.7211 3.35021 17.2519 2.88801C16.7827 2.42581 16.1713 2.13486 15.5162 2.0621"
        />
      </g>
      <defs>
        <clipPath id="clip0_42039_17206">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CompletedProjects;
