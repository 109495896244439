import { useEffect } from "react";

const SidebarModal = ({ isOpen, setIsOpen, content, bg, closeBtn }) => {
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <>
      {/* Backdrop with blur effect */}
      <div
        className={`fixed inset-0 z-40 h-screen w-screen bg-black-500 bg-opacity-50 transition-opacity ${
          !isOpen
            ? "pointer-events-none opacity-0"
            : "opacity-100 backdrop-blur-sm"
        }`}
        onClick={toggleSidebar}
      />

      {isOpen && closeBtn}
      {isOpen && (
        <div
          className={`no-scrollbar fixed inset-0 left-full z-40 h-screen w-full transform overflow-auto rounded-l-3xl shadow-xl  transition-transform md:w-fit ${
            isOpen ? "-translate-x-full" : "translate-x-0"
          } ${bg}`}
        >
          <div className="min-h-full ">{content}</div>
        </div>
      )}
    </>
  );
};

export default SidebarModal;
