import PieChartCard from "views/admin/default/components/PieChartCard";

import Widget from "./components/Widget";
import UserIcon from "./components/icons/UserIcon";
import TasksIcon from "views/admin/default/components/icons/TasksIcon";
import UsersListCard from "views/admin/default/components/UsersListCard";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import { usersColumns } from "views/admin/default/UsersList";
import { colors } from "helpers/StaticValues";

const Dashboard = () => {
  const [{ data }] = useAxios({
    url: "/dashboard/project-stats/",
    method: "GET",
  });

  const [{ data: users, loading: usersLoad, error: usersError }] = useAxios(
    {
      url: "/account/user_list",
    },
    {
      useCache: false,
    }
  );

  const [{ data: projectsByCat }] = useAxios(
    {
      url: "/dashboard/projects-by-category/",
    },
    {
      useCache: false,
    }
  );

  return (
    <div className="mt-5 justify-between gap-5 ">
      {/* Left Side */}
      <div className="flex-grow">
        {/* Card widget */}
        <div className="mt-3 grid grid-cols-2 gap-5 md:grid-cols-3 2xl:grid-cols-5">
          <Widget
            icon={<UserIcon />}
            textColor={"text-[#7660F9]"}
            title={"Total Users"}
            subtitle={users?.count || 0}
          />
          <Widget
            icon={<TasksIcon className={"stroke-[#6700E9]"} />}
            textColor={"text-[#6700E9]"}
            title={"Projects"}
            subtitle={data?.total_projects || 0}
          />
          <Widget
            icon={<TasksIcon className={"stroke-[#5FC9E1]"} />}
            textColor={"text-[#5FC9E1]"}
            title={"Pending Projects"}
            subtitle={data?.pending_projects || 0}
          />
          <Widget
            icon={<TasksIcon className={"stroke-[#0A8A08]"} />}
            textColor={"text-[#0A8A08]"}
            title={"Ongoing Projects"}
            subtitle={data?.ongoing_projects || 0}
          />
          <Widget
            icon={<TasksIcon className={"stroke-[#D62525]"} />}
            textColor={"text-[#D62525]"}
            title={"Completed Projects"}
            subtitle={data?.completed_projects || 0}
          />
        </div>

        {/* Tables & Charts */}

        <div className="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-3 ">
          {/* Check Table */}
          <div className="lg:col-span-2">
            {usersError && <Error error={usersError} />}
            {usersLoad ? (
              <Loading />
            ) : (
              <UsersListCard
                columnsData={usersColumns.filter(
                  (item) => item.Header !== "ACTION"
                )}
                tableData={users?.results || []}
              />
            )}
          </div>

          {/* Right side */}
          <PieChartCard
            title="Project by Category"
            data={projectsByCat || []}
            colors={colors}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
