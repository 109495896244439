import Card from "components/card";

const CeoWidget = ({ icon, title, subtitle, bg, subColor, titleColor }) => {
  return (
    <Card
      extra="!flex-row flex-grow justify-between shadow items-center rounded-lg"
      style={{ backgroundColor: bg }}
    >
      <div className="h-50 mt-2 ml-5 flex w-auto flex-col justify-center">
        <p
          className={`font-poppins text-sm 3xl:text-base ${
            titleColor ? titleColor : "text-white"
          }`}
        >
          {title}
        </p>
        <h4
          className={`text-2xl font-bold text-white ${
            subColor ? subColor : "text-white"
          }`}
        >
          {subtitle}
        </h4>
      </div>

      <div className="mr-1 flex h-[90px] w-auto flex-row items-center">
        <div className={`rounded-md p-3`}>
          <span className="flex items-center fill-white text-white ">
            {icon}
          </span>
        </div>
      </div>
    </Card>
  );
};

export default CeoWidget;
