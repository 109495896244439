import useAxios from "axios-hooks";
import Checkbox from "components/checkbox";
import Error from "components/error";
import Loading from "components/loading";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";

function RoleModalForm({ setSelectedUser, selectedUser, refetch }) {
  const [checked, setChecked] = useState(null);
  const [{ data: roles, loading, error }] = useAxios(
    {
      url: "/account/roles/",
      params: { no_pagination: true },
    },
    {
      useCache: false,
    }
  );
  const [{ loading: postLoading, error: postErr }, post] = useAxios(
    {
      url: "/account/user-roles/",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const CheckboxItem = ({ checked, setChecked, title, letter }) => (
    <div
      className={`mb-5 flex cursor-pointer items-center justify-between gap-2 rounded-lg border p-3 ${
        checked && "bg-[#F5FAFF] dark:bg-navy-600 dark:text-white"
      }`}
      onClick={setChecked}
    >
      <div className="flex items-center gap-2">
        <div
          className={`flex h-10 w-10 items-center justify-center rounded-full  p-2 text-lg ${
            checked ? "bg-[#485FE5] text-white" : "bg-[#F1F8FF] text-[#485FE5]"
          }`}
        >
          <span>{letter}</span>
        </div>
        <label htmlFor="name" className="text-[#667085] dark:text-white">
          {title}
        </label>
      </div>
      <Checkbox checked={checked} onChange={setChecked} />
    </div>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    post({
      data: {
        user_id: selectedUser?.profile?.user,
        role_id: checked,
      },
    })
      .then(() => {
        setSelectedUser(null);
        window.document
          .getElementById("RoleModalForm")
          .classList.replace("flex", "hidden");
        refetch();
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (selectedUser) {
      setChecked(selectedUser?.roles[0]?.role?.id);
    }
  }, [selectedUser]);

  return (
    <div
      id="RoleModalForm"
      tabIndex="-1"
      className="fixed inset-0 z-40 hidden w-full items-center justify-center overflow-y-scroll bg-gray-900/50 dark:bg-gray-900/80"
    >
      <div className="relative w-full rounded-lg bg-white dark:bg-navy-800 lg:w-[500px]">
        <button
          tabIndex="-1"
          className="absolute top-8 right-6"
          onClick={() => {
            window.document
              .getElementById("RoleModalForm")
              .classList.add("hidden");
          }}
        >
          <FiX className="mx-auto h-6 w-6 text-red-600" />
        </button>
        <div className="mt-5 p-8">
          <h4 className="text-2xl font-bold text-gray-800 text-opacity-70 dark:text-gray-200">
            Assign this role
          </h4>
          <p className="text-[#667085] dark:text-gray-400">
            Select one or multiple employees to assign to this roles.
          </p>

          <form className="mt-5 rounded-lg border p-5" onSubmit={handleSubmit}>
            {(loading || postLoading) && <Loading />}
            {(error || postErr) && <Error error={error || postErr} />}

            {!loading &&
              !postLoading &&
              roles?.length > 0 &&
              roles?.map((role) => (
                <CheckboxItem
                  key={role.id}
                  title={role.name}
                  letter={role.name[0]}
                  checked={checked ? checked === role?.id : false}
                  setChecked={() => setChecked(role.id)}
                />
              ))}

            <button
              className="mt-5 w-full rounded-lg bg-[#485FE5] p-4 text-white"
              type="submit"
            >
              Assign
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
export default RoleModalForm;
