import React from "react";

function Bars() {
  return (
    <svg
      width="54"
      height="50"
      viewBox="0 0 54 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="47.9385"
        y="49.2236"
        width="49.2205"
        height="5.76"
        rx="2.88"
        transform="rotate(-90 47.9385 49.2236)"
        fill="#E9EDF7"
      />
      <rect
        x="47.5845"
        y="49.1855"
        width="43.3967"
        height="5.78624"
        rx="2.89312"
        transform="rotate(-90 47.5845 49.1855)"
        fill="#C49E13"
      />
      <rect
        x="32.2563"
        y="49.2236"
        width="49.2205"
        height="5.75995"
        rx="2.87998"
        transform="rotate(-90 32.2563 49.2236)"
        fill="#E9EDF7"
      />
      <rect
        x="32.1548"
        y="49.1855"
        width="38.5749"
        height="5.78624"
        rx="2.89312"
        transform="rotate(-90 32.1548 49.1855)"
        fill="#D8B431"
      />
      <rect
        x="16.5762"
        y="49.2236"
        width="49.2205"
        height="5.76001"
        rx="2.88"
        transform="rotate(-90 16.5762 49.2236)"
        fill="#E9EDF7"
      />
      <rect
        x="16.7251"
        y="49.1855"
        width="32.7887"
        height="5.78624"
        rx="2.89312"
        transform="rotate(-90 16.7251 49.1855)"
        fill="#E4BF36"
      />
      <rect
        x="0.895508"
        y="49.2236"
        width="49.2205"
        height="5.76002"
        rx="2.88001"
        transform="rotate(-90 0.895508 49.2236)"
        fill="#E9EDF7"
      />
      <rect
        x="0.895508"
        y="49.2236"
        width="25.3675"
        height="5.76001"
        rx="2.88001"
        transform="rotate(-90 0.895508 49.2236)"
        fill="#F5CF46"
      />
    </svg>
  );
}

export default Bars;
