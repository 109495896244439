import React from "react";

function DownArrow({ className }) {
  return (
    <svg
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62668 0C10.0459 0 10.279 0.484966 10.0171 0.812347L5.72409 6.17862C5.52393 6.42883 5.14339 6.42883 4.94322 6.17862L0.650203 0.812347C0.388297 0.484965 0.621385 0 1.04064 0L9.62668 0Z"
        fill="#B5B5BE"
      />
    </svg>
  );
}

export default DownArrow;
