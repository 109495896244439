import React from "react";

function CreativeBoxs() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.71115 23.3668L2.09312 18.5459L9.71115 23.3668ZM9.71115 23.3668V14.8268L2.07788 10.2793V18.5119C2.07788 18.5208 2.08016 18.5288 2.08353 18.535C2.08684 18.541 2.09061 18.5443 2.09309 18.5459L9.71115 23.3668ZM22.3617 18.535C22.3617 18.535 22.3617 18.535 22.3617 18.535C22.3584 18.541 22.3546 18.5443 22.3521 18.5459L22.3617 18.535ZM22.3617 18.535C22.3651 18.5288 22.3674 18.5208 22.3674 18.5119V10.2801M22.3617 18.535L22.3674 10.2801M22.3674 10.2801L14.7341 14.8256V23.3668L22.3521 18.5459L22.3674 10.2801ZM12.2181 1.81784C12.2207 1.81621 12.2222 1.81615 12.2226 1.81615C12.2231 1.81615 12.2246 1.81621 12.2272 1.81784L12.8934 0.76511L12.2272 1.81785L19.2507 6.2628L12.2226 10.449L5.19451 6.2628L12.2181 1.81785L11.5565 0.772517L12.2181 1.81784Z"
        stroke="white"
        strokeWidth="2.49168"
      />
    </svg>
  );
}

export default CreativeBoxs;
