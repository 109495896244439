import React from "react";

function Tasks() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3389 8.13965H16.1514"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.84863 8.13965L6.53613 8.82715L8.59863 6.76465"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3389 14.5566H16.1514"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.84863 14.5566L6.53613 15.2441L8.59863 13.1816"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.24967 20.1663H13.7497C18.333 20.1663 20.1663 18.333 20.1663 13.7497V8.24967C20.1663 3.66634 18.333 1.83301 13.7497 1.83301H8.24967C3.66634 1.83301 1.83301 3.66634 1.83301 8.24967V13.7497C1.83301 18.333 3.66634 20.1663 8.24967 20.1663Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Tasks;
