import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes.js";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import logo from "assets/img/layout/MoLS-logo.png";
import SignIn from "views/auth/SignIn";
import SignUp from "views/auth/SignUp";

export default function Auth() {
  const authRoutes = [
    {
      name: "Sign In",
      layout: "/auth",
      path: "sign-in",
      component: <SignIn />,
    },
    {
      name: "Sign Up",
      layout: "/auth",
      path: "sign-up",
      component: <SignUp />,
    },
  ];
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div className="flex">
            <div className="hidden min-h-full flex-col items-center justify-center bg-[#F3F5F9] dark:bg-navy-800 lg:flex lg:min-w-[45%] 3xl:min-w-[50%]">
              <div className="relative flex h-40 w-40 items-center justify-center overflow-hidden rounded-full bg-[#CBDBEC] ">
                {/*  */}
                {/* <div className="absolute top-0 left-0 h-1/2 w-40 rotate-180 bg-[#5d7dbed8] opacity-70 blur-sm" /> */}
                {/*  */}

                <img
                  src={logo}
                  alt="auth-bg"
                  className="z-20 h-40 w-40 object-cover p-8"
                />
              </div>
            </div>
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12  lg:h-screen lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                <Routes>
                  {getRoutes(routes)}
                  {getRoutes(authRoutes)}
                  <Route
                    path="/"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
