import React from "react";

function AllProjects() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.09875 13.5987L1.32625 13.8263L2.42375 14.9237C2.59792 14.7497 2.80466 14.6116 3.03219 14.5174C3.25972 14.4232 3.50357 14.3748 3.74982 14.3748C3.99607 14.3749 4.23989 14.4235 4.46738 14.5177C4.69486 14.612 4.90154 14.7502 5.07563 14.9244C5.24971 15.0985 5.38778 15.3053 5.48197 15.5328C5.57615 15.7603 5.62459 16.0042 5.62453 16.2504C5.62448 16.4967 5.57592 16.7405 5.48163 16.968C5.38734 17.1955 5.24917 17.4022 5.075 17.5763L6.175 18.6737L6.40125 18.9013L7.5 20L8.825 18.675L18.675 8.825L20 7.5L18.9013 6.40125L18.6737 6.17375L17.5763 5.07625C17.4022 5.25042 17.1955 5.38859 16.968 5.48288C16.7405 5.57717 16.4967 5.62573 16.2504 5.62578C15.7531 5.6259 15.2761 5.42845 14.9244 5.07688C14.7502 4.90279 14.612 4.69611 14.5177 4.46863C14.4235 4.24114 14.3749 3.99732 14.3748 3.75107C14.3747 3.25374 14.5722 2.77674 14.9237 2.425L13.8263 1.325L13.5987 1.09875L12.5 0L11.175 1.325L1.325 11.175L0 12.5L1.09875 13.5987ZM3.75 12.5C3.415 12.5 3.08875 12.545 2.77875 12.6275L2.65125 12.5L8.16375 6.98875L13.0113 11.8375L7.5 17.3487L7.3725 17.2213C7.5215 16.6656 7.54066 16.0831 7.42848 15.5188C7.31631 14.9546 7.07582 14.4237 6.72561 13.9672C6.3754 13.5108 5.92487 13.1411 5.40889 12.8867C4.89291 12.6322 4.3253 12.4999 3.75 12.5ZM14.3375 10.5113L17.3487 7.5L17.2213 7.3725C16.5853 7.54316 15.9155 7.54334 15.2795 7.37303C14.6434 7.20271 14.0633 6.8679 13.5977 6.40228C13.1321 5.93666 12.7973 5.35663 12.627 4.72055C12.4567 4.08446 12.4568 3.41474 12.6275 2.77875L12.5 2.65125L9.48875 5.66375L14.3375 10.5113Z"
      />
    </svg>
  );
}

export default AllProjects;
