import { useState } from "react";
import {
  IoChevronDownCircleOutline,
  IoInformationCircle,
} from "react-icons/io5";
import ActivityRow from "views/admin/expert/components/ActivityRow";
import ActivityRowForm from "views/admin/ceo/components/ActivityRowForm";
import MilestoneRowDetails from "views/admin/expert/components/MilestoneRowDetails";

function ProjectMilestoneDetail({ data }) {
  const [showActivity, setShowActivity] = useState(false);
  const [showMilestone, setShowMilestone] = useState(false);
  const [showMileForm, setShowMileForm] = useState(false);
  const [showActivityForm, setShowActivityForm] = useState(false);

  return (
    <div className="mt-6 rounded-lg bg-[#E9EDF5] py-1 px-2 dark:bg-navy-900">
      <div className="flex justify-between">
        <h1 className="ml-3 pt-3 font-semibold text-navy-500">
          {data?.milestone_name}
        </h1>
      </div>
      {/*  */}
      <div className="mt-3">
        <div className="flex items-center justify-between rounded-lg bg-white px-4 py-2  dark:bg-gray-200 ">
          <span className="text-sm text-gray-700 dark:text-gray-900 ">
            Project Milestone
          </span>
          <div className={`flex gap-1`}>
            <IoChevronDownCircleOutline
              color="#687182"
              className={`h-5 w-5 transform cursor-pointer ${
                showMilestone ? "rotate-180" : "rotate-0"
              }`}
              onClick={() => {
                setShowMilestone(!showMilestone);
                setShowMileForm(false);
              }}
            />
          </div>
        </div>
        <MilestoneRowDetails
          showMilestone={showMilestone}
          showMileAdd={showMileForm}
          milestone={data}
        />
        {/*  */}
        <div className="mb-2 mt-3 rounded-lg 2xl:ml-14">
          <div className="flex  items-center justify-between rounded-lg bg-white px-4 py-2 dark:bg-gray-200 ">
            <span className="text-sm text-gray-700 dark:text-gray-900 ">
              Activities
            </span>
            <div className={`flex gap-1`}>
              <IoChevronDownCircleOutline
                color="#687182"
                className={`h-5 w-5 transform cursor-pointer ${
                  showActivity ? "rotate-180" : "rotate-0"
                }`}
                onClick={() => {
                  setShowActivity(!showActivity);
                  setShowActivityForm(false);
                }}
              />
            </div>
          </div>
          <div
            className={`${
              showActivity === true || showActivityForm === true
                ? "block"
                : "hidden"
            } px-1.5 py-2 text-gray-700`}
          >
            <div className="flex flex-col items-end text-center font-poppins text-sm text-[#707070] dark:text-white">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-[#F7F9FCCC] text-[#687182]">
                    <th></th>

                    <th className="text-start font-normal">
                      <p className="ml-4"> {data?.project_name}</p>
                    </th>
                    <th className="font-normal">Description</th>
                    <th className="font-normal">ST</th>
                    <th className="">
                      <div className="flex items-center justify-center gap-2 font-normal uppercase">
                        <p>status</p>
                        <IoInformationCircle />
                      </div>
                    </th>
                    <th>
                      <div className="flex items-center justify-center gap-2 font-normal uppercase">
                        <p>Project timeline</p>
                        <IoInformationCircle />
                      </div>
                    </th>
                    <th className="font-normal">Budget Estimation</th>
                    <th></th>
                  </tr>
                </thead>
                {showActivityForm ? (
                  <tbody>
                    <ActivityRowForm />
                  </tbody>
                ) : (
                  data?.activities?.map((activity, index) => {
                    return <ActivityRow key={index} data={activity} />;
                  })
                )}
              </table>
              {showActivityForm && (
                <button className="mt-4 -mb-3 rounded-md bg-brand-600 py-2 px-4 text-white">
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default ProjectMilestoneDetail;
