import Card from "components/card";
import ActivityViewModal from "components/popover/ActivityViewModal";
import { BsChevronRight } from "react-icons/bs";
import { IoAddCircleOutline } from "react-icons/io5";
import SubActivityModal from "views/admin/expert/components/SubActivityModal";

function ActivityRowCard({ activity }) {
  const path = window.location.pathname;
  return (
    <Card className="mb-2 !w-full rounded bg-white p-4 text-start">
      <ActivityViewModal id={"activity" + activity.id} data={activity} />
      <div className="flex min-w-[200px] items-center justify-between">
        <p className="text-sm font-semibold">{activity?.activity_name}</p>
        <div className=" ml-3 flex w-fit items-center gap-2 rounded-lg bg-[#EDEDFC] p-1">
          <div className="ml-1 h-2 w-2 rounded-md bg-[#5E5ADB]" />
          <p className="mr-2 text-[#5E5ADB]">{activity?.status}</p>
        </div>
      </div>
      <div className="mt-3 flex items-center justify-between gap-2">
        <p className="font-semibold">Timeline:</p>
        <div className="flex items-center gap-2">
          <p className="rounded bg-[#464F60]/10 p-1.5 text-xs">
            {activity.start_date}
          </p>
          <BsChevronRight size={10} />
          <p className="rounded bg-[#464F60]/10 p-1.5 text-xs">
            {activity.end_date}
          </p>
        </div>
      </div>
      <p className="mt-3 ">
        <span className="font-semibold">Budget:</span>{" "}
        {activity?.activity_budget}
      </p>
      <p className="mt-2 max-w-[200px] truncate text-start text-gray-700">
        <span className="font-semibold">Description : </span>
        {activity.activity_description}
      </p>

      <p
        className="mt-1 cursor-pointer text-end text-blueSecondary"
        onClick={() => {
          window.document
            .getElementById(`activity${activity.id}`)
            .classList.replace("hidden", "flex");
        }}
      >
        see more
      </p>
      {/* list sub_activities for mobile view */}
      {activity?.sub_activities?.length > 0 && (
        <div className="mt-5">
          {path.includes("expert") && (
            <>
              <SubActivityModal activity={activity} key={activity?.id} />

              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold">Sub activities</p>
                <IoAddCircleOutline
                  className="h-4 w-4 cursor-pointer"
                  onClick={() => {
                    window.document
                      .getElementById(`SubtaskModalForm${activity.id}`)
                      .classList.replace("hidden", "flex");
                  }}
                />
              </div>
            </>
          )}
          <div className="flex flex-col gap-2">
            {activity?.sub_activities?.map((sub) => (
              <div
                key={sub.id}
                className="mt-3 flex w-full justify-between text-sm"
              >
                <ActivityViewModal id={"sub" + sub.id} data={sub} />
                <p>{sub.activity_name}</p>
                <p
                  className="cursor-pointer text-blueSecondary"
                  onClick={() => {
                    window.document
                      .getElementById(`sub${sub.id}`)
                      .classList.replace("hidden", "flex");
                  }}
                >
                  view
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </Card>
  );
}
export default ActivityRowCard;
