import { useState } from "react";

function PasswordInputField({
  label,
  id,
  type,
  placeholder,
  variant = "auth",
  state,
  disabled,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label} {rest.required && <span className="text-red-600">*</span>}
      </label>
      <div className="relative">
        <input
          id={id}
          type="password"
          className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none ${
            disabled === true
              ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
              : state === "error"
              ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
              : state === "success"
              ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          }`}
          placeholder={placeholder}
          {...rest}
        />
        <button
          type="button"
          onClick={() => {
            // setShowPassword(!showPassword);

            const password = document.getElementById(id);
            if (showPassword) {
              password.type = "password";
              setShowPassword(false);
            } else {
              password.type = "text";
              setShowPassword(true);
            }
          }}
          className="absolute top-0 right-0 p-3.5 rounded-e-md"
        >
          <svg
            className="size-3.5 dark:text-neutral-600 flex-shrink-0 text-gray-400"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path
              className={`${showPassword ? "block" : "hidden"}`}
              d="M9.88 9.88a3 3 0 1 0 4.24 4.24"
            ></path>
            <path
              className={`${showPassword ? "block" : "hidden"}`}
              d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"
            ></path>
            <path
              className={`${showPassword ? "block" : "hidden"}`}
              d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"
            ></path>
            <line
              className={`${showPassword ? "block" : "hidden"}`}
              x1="2"
              x2="22"
              y1="2"
              y2="22"
            ></line>
            <path
              className={`${showPassword ? "hidden" : "block"}`}
              d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"
            ></path>
            <circle
              className={`${showPassword ? "hidden" : "block"}`}
              cx="12"
              cy="12"
              r="3"
            ></circle>
          </svg>
        </button>
      </div>
    </div>
  );
}

export default PasswordInputField;
