import CalendarOutline from "components/icons/CalendarOutline";

function MilestoneCard({ data }) {
  return (
    <div className="mt-5 rounded-xl bg-white py-3 px-5 dark:bg-navy-900">
      <p className="my-3 mt-3 text-sm ">{data?.milestone_name}</p>
      <p className="text-xs text-gray-700 dark:text-gray-200">
        {data?.milestone_description}
      </p>

      <div className="mt-5 flex items-center justify-between ">
        <div className="flex items-center gap-5">
          <div className="flex items-center gap-3 text-xs text-gray-900 dark:text-gray-200">
            <CalendarOutline />
            {data?.start_date}
          </div>
          {/* <div className="flex items-center gap-1 text-xs text-gray-900 dark:text-gray-200">
            <div className="relative">
              <Message />
              <Badge />
            </div>{" "}
            2
          </div>
          <div className="flex items-center gap-1 text-xs text-gray-900 dark:text-gray-200">
            <Clip /> 4
          </div> */}
        </div>
        {/* <img className="h-6 w-6 rounded-full" src={avatar} alt="avatar" /> */}
      </div>
    </div>
  );
}

export default MilestoneCard;
