function Error({ error }) {
  return (
    <div
      className="relative my-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
      role="alert"
    >
      <strong className="mr-3 font-bold">Error!</strong>
      {error?.response?.status !== 500 ? (
        error?.response?.data && (
          <pre className="mt-2 max-w-full rounded bg-red-200 p-2 xl:max-w-4xl">
            {JSON.stringify(error.response.data, null, 2).replace(
              /[[\]{}""," "]+/g,
              " "
            )}
          </pre>
        )
      ) : (
        <pre className="mt-2 rounded bg-red-200 p-2 text-sm">
          Server error. Please try again later or contact the administrator.
        </pre>
      )}
    </div>
  );
}
export default Error;
