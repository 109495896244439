import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useMemo } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
const ComplexTable = (props) => {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto bg-[#F5FAFF]"}>
      <div className="mt-2 h-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full p-4">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 pl-3 pr-2 pb-[10px] text-start dark:!border-navy-900"
                  >
                    <p className="text-xs tracking-wide text-gray-700">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className={`
                  ${
                    index % 2 === 0
                      ? "bg-gray-100 dark:bg-navy-800"
                      : "bg-white dark:bg-navy-900"
                  }
                `}
                >
                  {row.cells.map((cell, i) => {
                    let data = "";
                    if (cell.column.Header === "Email") {
                      data = (
                        <p className="pl-3 text-sm font-bold text-navy-900 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "Name") {
                      data = (
                        <p className="pl-3 text-sm font-bold text-navy-900 dark:text-white">
                          {cell.value
                            ? cell.row.original.profile?.first_name +
                              " " +
                              cell.row.original.profile?.last_name
                            : "N/A"}
                        </p>
                      );
                    } else if (cell.column.Header === "No") {
                      data = (
                        <p className="pl-3 text-sm font-bold text-navy-900 dark:text-white">
                          {Number(index + 1).toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                          })}
                        </p>
                      );
                    } else if (cell.column.Header === "Account Status") {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className={`rounded-full text-xl`}>
                            {cell.value === true ? (
                              <div className="flex min-w-[90px] items-center gap-2 rounded-xl bg-[#ECFDF3] py-0.5 px-3">
                                <div className="h-2 w-2 rounded-lg bg-[#12B76A]" />
                                <p className="text-sm font-bold text-[#027A48]">
                                  Active
                                </p>
                              </div>
                            ) : cell.value === false ? (
                              <div className="flex min-w-[90px] items-center gap-2 rounded-xl bg-[#FFF2EA] py-0.5 px-3">
                                <div className="h-2 w-2 rounded-lg bg-[#F15046]" />
                                <p className="text-sm font-bold text-[#F15046]">
                                  Inactive
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === "Role") {
                      data =
                        cell.value === "Expert" ? (
                          <p className="w-[110px] rounded-lg bg-[#CCE4FF] px-2 py-1 text-center text-sm font-bold text-[#0064D9]  dark:text-navy-800">
                            {cell.value}
                          </p>
                        ) : (
                          <p className="w-[110px] rounded-lg bg-[#40B2B726] px-2 py-1 text-center text-sm font-bold text-[#40B2B7]  dark:text-white">
                            {cell.value}
                          </p>
                        );
                    } else if (cell.column.Header === "Action") {
                      data = (
                        <button className="flex items-center gap-2 rounded-lg bg-brand-500 px-3 text-white">
                          <FaExternalLinkAlt size={13} /> <p>open</p>
                        </button>
                      );
                    }
                    return (
                      <td
                        className="w-fit px-0 pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={i}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ComplexTable;
