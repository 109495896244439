import ProjectsByR from "views/admin/default/components/ProjectsByRegionChart";
import Widget from "components/widget/Widget";
import Tasks from "components/icons/Tasks";
import CreativeBoxs from "components/icons/CreativeBoxs";
import CategoryIcon from "components/icons/CategoryIcon";
import ProjectsByMonth from "views/admin/default/components/ProjectsByMonth";
import useAxios from "axios-hooks";
import Card from "components/card";
import PieChartCard from "views/admin/default/components/PieChartCard";
import ComplexTable from "views/admin/ceo/components/ComplexTable";
import { complexColumns } from "views/admin/ceo";
import { colors } from "helpers/StaticValues";
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

const HODashboard = () => {
  const navigate = useNavigate();
  const [{ data }] = useAxios({
    url: "/dashboard/project-stats/",
    method: "GET",
  });
  const [{ data: projects }] = useAxios(
    {
      url: "/main_app/projects/",
      method: "GET",
    },
    { useCache: false }
  );

  const [{ data: projectsRegion }] = useAxios({
    url: "/dashboard/projects-by-region/",
    method: "GET",
  });
  const [{ data: projectsMonth }] = useAxios({
    url: "/dashboard/projects-by-month/",
    method: "GET",
  });
  const [{ data: projectsSec }] = useAxios({
    url: "/dashboard/projects-by-sector/",
    method: "GET",
  });
  const [{ data: projectsCat }] = useAxios({
    url: "/dashboard/projects-by-category/",
    method: "GET",
  });

  return (
    <div className="mt-5 justify-between gap-5 3xl:flex">
      {/* Left Side */}
      <div className="flex-grow">
        {/* Card widget */}
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
          <Widget
            icon={<CategoryIcon />}
            extra={"bg-[#7660F9]"}
            title={"Total Projects"}
            subtitle={data?.total_projects || 0}
          />

          <Widget
            icon={<Tasks />}
            extra={"bg-[#37AC50]"}
            title={"Pending Projects"}
            subtitle={data?.pending_projects || 0}
          />

          <Widget
            icon={<CreativeBoxs />}
            extra={"bg-[#00A3C7]"}
            title={"Ongoing Projects"}
            subtitle={data?.ongoing_projects || 0}
          />

          <Widget
            icon={<Tasks />}
            extra={"bg-[#C87325]"}
            title={"Completed Projects"}
            subtitle={data?.completed_projects || 0}
          />
        </div>

        {/* Charts */}

        <div className="mt-5 grid w-full grid-cols-1 gap-5 md:grid-cols-2">
          <div className="flex flex-col gap-2">
            {/* <TotalSpent /> */}
            <Card extra="bg-[#e6e6e659]/10 mb-3  p-[20px] pb-0 shadow-md">
              <div className="p-4">
                <h1 className="text-2xl text-[#787486] dark:text-white">
                  All Registered Projects per Year
                </h1>
                <p className="mt-1 text-[#787486] dark:text-white">
                  Mols Projects
                </p>

                <p className="float-right text-4xl font-[700] text-[#485FE5]">
                  {Number(
                    data?.projects_per_year[0]?.count || 0
                  ).toLocaleString()}
                </p>
              </div>
            </Card>
            <ProjectsByMonth data={projectsMonth} />
          </div>
          <ProjectsByR data={projectsRegion} />
        </div>

        {/* Tables & Charts */}

        <div className="mt-5 grid grid-cols-1 gap-5 ">
          {/* Projects Table */}
          <Card extra="h-full rounded-lg bg-[#FAFAFA] p-5">
            <p className="mb-3 pl-5 text-xl font-[600] text-[#787486]">
              Projects Table
            </p>
            <ComplexTable
              columnsData={complexColumns}
              tableData={projects?.results || []}
            />
            <button
              className="mr-2 mt-auto flex items-center gap-2 self-end pr-1 pb-4 font-semibold text-brand-800 dark:text-navy-100"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(`/higher-official/projects`);
              }}
            >
              <p>View all</p>
              <BsArrowRight size={20} />
            </button>
          </Card>
        </div>
      </div>
      {/* Right side */}
      <div className="mt-3 flex flex-col gap-6 xl:min-w-[27%]">
        <PieChartCard
          title={"Projects by sector"}
          data={projectsSec}
          colors={colors}
        />
        <PieChartCard
          title={"Projects by category"}
          data={projectsCat}
          colors={colors}
        />
      </div>
    </div>
  );
};

export default HODashboard;
