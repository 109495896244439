import StatusDropdown from "components/dropdown/StatusDropdown";
import StatusShow from "components/StatusShow";
import { FaChevronDown } from "react-icons/fa";

function ActivityRowForm({
  activityName,
  setActivityName,
  activityStatus,
  setActivityStatus,
  activityStart,
  setActivityStart,
  activityEnd,
  setActivityEnd,
  activityBudget,
  setActivityBudget,
  activityDesc,
  setActivityDesc,
}) {
  return (
    <div className="items-start justify-between gap-10 rounded-lg bg-white py-2 px-4 text-start text-gray-700 dark:bg-navy-800 dark:text-white md:flex">
      {/* left side */}
      <div className="flex-grow">
        <div className="mt-4 flex items-center justify-between">
          <div>
            <h1>
              Activity Name <span className="text-red-700">*</span>
            </h1>
            <p className="text-xs text-gray-500">Write a name</p>
          </div>
          <input
            id="activityName"
            type="text"
            placeholder="Activity Name"
            className="w-[55%] rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-700"
            onChange={(e) => setActivityName(e.target.value)}
            value={activityName}
            required
          />
        </div>
        <div className="mt-4 flex flex-grow items-center justify-between">
          <div>
            <h1>Activity Description</h1>
            <p className="text-xs text-gray-500">Short Description</p>
          </div>
          <textarea
            id="activityDescription"
            placeholder="Activity Description"
            className="w-[55%] rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-700"
            onChange={(e) => setActivityDesc(e.target.value)}
            value={activityDesc}
          />
        </div>
        <div className="mt-4 flex flex-grow items-center justify-between">
          <div>
            <h1 className="">
              Activity Budget <span className="text-red-700">*</span>
            </h1>
            <p className="text-xs text-gray-500">Write a budget</p>
          </div>
          <input
            id="activityBudget"
            type="number"
            pattern="[0-9]*"
            placeholder="Activity Budget"
            className="w-[55%] rounded-lg  bg-gray-50 py-4 px-2 dark:bg-navy-700"
            onChange={(e) => setActivityBudget(e.target.value)}
            value={activityBudget}
            required
          />
        </div>
      </div>
      {/* Right side */}
      <div className=" flex-grow">
        <div className="mt-4 flex flex-grow items-center justify-between">
          <div>
            <h1>
              Start Date <span className="text-red-700">*</span>
            </h1>
            <p className="text-xs text-gray-500">Type Date</p>
          </div>
          <input
            id="activityStartDate"
            type="date"
            placeholder="Start Date"
            className="w-[55%] rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-700"
            onChange={(e) => setActivityStart(e.target.value)}
            value={activityStart}
            required
          />
        </div>
        <div className="mt-4 flex flex-grow items-center justify-between">
          <div>
            <h1>
              End Date <span className="text-red-700">*</span>
            </h1>
            <p className="text-xs text-gray-500">Type Date</p>
          </div>
          <input
            id="activityEndDate"
            type="date"
            placeholder="Start Date"
            className="w-[55%] rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-700"
            onChange={(e) => setActivityEnd(e.target.value)}
            value={activityEnd}
            required
          />
        </div>
        <div className="mt-4 flex flex-grow items-center justify-between">
          <div>
            <h1>
              Status <span className="text-red-700">*</span>
            </h1>
            <p className="text-xs text-gray-500">Choose status</p>
          </div>
          <StatusDropdown
            pos={"-left-24"}
            button={
              <StatusShow status={activityStatus}>
                <FaChevronDown size={10} className="cursor-pointer" />
              </StatusShow>
            }
            onSubmit={(value) => setActivityStatus(value)}
          />
        </div>
      </div>
    </div>
  );
}

export default ActivityRowForm;
