import { useState } from "react";

function SubTaskForm({ onSubmit }) {
  const [selected] = useState("Ongoing");
  return (
    <div className="flex min-w-[220px] flex-col items-start gap-2 rounded-lg bg-white p-4 dark:bg-navy-700">
      <h1 className="font-poppins text-sm font-semibold capitalize">
        Sub activity name <span className="text-red-700">*</span>
      </h1>
      <textarea
        name="subTask"
        id="subTask"
        rows={2}
        className="w-full rounded-lg bg-[#F7F8FA] p-2 dark:bg-navy-900"
      />
      <h1 className="font-poppins text-sm font-semibold capitalize">
        Project timeline
      </h1>
      <div className="flex items-center gap-2 text-start">
        <div>
          <p className="mb-1">Start Date</p>
          <input
            type="date"
            className="w-[150px] rounded-lg bg-[#F7F8FA] p-2 dark:bg-navy-900"
          />
        </div>
        <div>
          <p className="mb-1">End Date</p>
          <input
            type="date"
            className="w-[150px] rounded-lg bg-[#F7F8FA] p-2 dark:bg-navy-900"
          />
        </div>
      </div>
      <h1 className="font-poppins text-sm font-semibold capitalize">
        Estimation
      </h1>
      <input
        id="subTask"
        name="subTask"
        className="w-full rounded-lg bg-[#F7F8FA] p-2 dark:bg-navy-900"
      />

      <div className="mt-2 flex w-full items-center justify-end gap-2">
        <button className="rounded-lg bg-[#F7F8FA] p-2 px-4 dark:text-gray-800">
          Cancel
        </button>
        <button
          className="rounded-lg bg-[#5E5ADB] p-2 px-4 text-white"
          onClick={() => onSubmit(selected)}
        >
          Add task
        </button>
      </div>
    </div>
  );
}
export default SubTaskForm;
