import Card from "components/card";

const Widget = ({ icon, title, subtitle, extra }) => {
  return (
    <Card extra="!flex-row flex-grow shadow-md items-center bg-[#e6e6e659]/10 rounded-md">
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div className={`rounded-md p-3 ${extra}`}>
          <span className="flex items-center fill-white text-brand-500 dark:text-white">
            {icon}
          </span>
        </div>
      </div>

      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <p className="font-poppins text-sm text-gray-700 dark:text-gray-300 3xl:text-base">
          {title}
        </p>
        <h4 className="text-xl font-bold text-[#787486] dark:text-white">
          {subtitle}
        </h4>
      </div>
    </Card>
  );
};

export default Widget;
