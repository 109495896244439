import React from "react";

function ChevronRightOutline({ className, ...props }) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 15 14"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.96967 8.96967C5.67678 9.26256 5.67678 9.73744 5.96967 10.0303C6.26256 10.3232 6.73744 10.3232 7.03033 10.0303L9.53033 7.53033C9.82322 7.23744 9.82322 6.76256 9.53033 6.46967L7.03033 3.96967C6.73744 3.67678 6.26256 3.67678 5.96967 3.96967C5.67678 4.26256 5.67678 4.73744 5.96967 5.03033L7.93934 7L5.96967 8.96967Z"
        fill="#868FA0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 0C11.366 -1.68988e-07 14.5 3.13401 14.5 7C14.5 10.866 11.366 14 7.5 14C3.63401 14 0.5 10.866 0.5 7C0.5 3.13401 3.63401 1.68988e-07 7.5 0ZM7.5 1.5C10.5376 1.5 13 3.96243 13 7C13 10.0376 10.5376 12.5 7.5 12.5C4.46243 12.5 2 10.0376 2 7C2 3.96243 4.46243 1.5 7.5 1.5Z"
        fill="#868FA0"
      />
    </svg>
  );
}

export default ChevronRightOutline;
