/* eslint-disable react-hooks/exhaustive-deps */
import useAxios from "axios-hooks";
import Card from "components/card";
import Error from "components/error";
import Bars from "components/icons/Bars";
import BarsFilled from "components/icons/BarsFilled";
import Project from "components/icons/Project";
import Loading from "components/loading";
import { useContext, useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import ActivityProgress from "views/admin/ceo/components/ActivityProgress";
import CeoWidget from "views/admin/ceo/components/CeoWidget";
import MilestoneCard from "views/admin/ceo/components/MilestoneCard";
import PieChartCard from "views/admin/ceo/components/PieChartCard";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "variables/charts";
import LineChart from "components/charts/LineChart";
import { HoRouteNameContext } from "layouts/HO";

function ProjectDashboard() {
  const navigate = useNavigate();
  const params = useParams();
  const { setCurrentRoute } = useContext(HoRouteNameContext);

  const [{ data, loading, error }, fetchData] = useAxios(
    {
      url: `/dashboard/projects/${params.id}/milestones/`,
      method: "GET",
    },
    { manual: true, useCache: false }
  );

  const [{ data: mileProgress }, fetchMileProgress] = useAxios(
    {
      url: `/dashboard/projects/${params.id}/milestones/progress/`,
      method: "GET",
    },
    { manual: true, useCache: false }
  );

  const [{ data: projectStatus }, fetchStatus] = useAxios(
    {
      url: `/dashboard/projects/${params.id}/status/`,
      method: "GET",
    },
    { manual: true, useCache: false }
  );

  const [{ data: milestones }, fetchMilesotnes] = useAxios(
    {
      url: `/main_app/milestones/by_project/${params.id}/`,
      method: "GET",
    },
    { manual: true, useCache: false }
  );

  useEffect(() => {
    if (params.id) {
      fetchData();
      fetchMileProgress();
      fetchStatus();
      fetchMilesotnes();
    }
  }, [params.id]);

  useEffect(() => {
    if (projectStatus) {
      setCurrentRoute(`${projectStatus.project_name} Dashboard`);
    }
  }, [projectStatus]);

  if (!navigate || !params.id || loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className="mt-10 items-start gap-4 3xl:flex">
      <div>
        {/* Wideg cards */}
        <div className="grid grid-cols-1 gap-3 md:grid-cols-2 2xl:grid-cols-4">
          <CeoWidget
            icon={<Project className="h-10 w-10 fill-white" />}
            title="All Milestones"
            subtitle={data?.total_milestones || 0}
            bg="#6D80EC"
          />
          <CeoWidget
            icon=<Bars />
            title="Pending Milestones"
            subtitle={data?.pending_milestones || 0}
            bg="#F5FAFF"
            titleColor="text-[#A3AED0]"
            subColor="text-[#F5CF46]"
          />
          <CeoWidget
            icon=<BarsFilled />
            title="Ongoing Milestones"
            subtitle={data?.ongoing_milestones || 0}
            bg="#F5FAFF"
            titleColor="text-[#A3AED0]"
            subColor="text-[#52A57E]"
          />
          <CeoWidget
            icon=<Project className="h-10 w-10 fill-red-400" />
            title="Completed Milestones"
            subtitle={data?.completed_milestones || 0}
            bg="#F5FAFF"
            titleColor="text-[#A3AED0]"
            subColor="text-red-400"
          />
        </div>

        {/* Bar chart */}
        <div className="mt-5 grid w-full grid-cols-1 gap-5 md:grid-cols-3">
          <div className="col-span-2">
            <Card extra="!p-[20px] max-h-[390px] h-full text-center bg-[#F5FAFF] shadow">
              <div className="flex justify-between">
                <p className="text-gray-800">Project budget performance</p>

                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-1.5">
                    <div className="h-2.5 w-2.5 rounded-full bg-red-600" />
                    <p className="text-[#615E83]">Planned Budget</p>
                  </div>

                  <div className="flex items-center gap-1.5">
                    <div className="h-2.5 w-2.5 rounded-full bg-[#4A3AFF]" />
                    <p className="text-[#615E83]">Spending Budget</p>
                  </div>
                </div>
              </div>

              <LineChart
                options={lineChartOptionsTotalSpent}
                series={lineChartDataTotalSpent}
              />
            </Card>
          </div>
          {
            <div className="col-span-1">
              <PieChartCard title={"Project Status"} data={projectStatus} />
            </div>
          }
        </div>
        {/* Pie chart */}
        <div className="mt-5 w-full">
          <ActivityProgress data={mileProgress} link="higher-official" />
        </div>
      </div>

      {/* Right side */}
      <div className="mt-4 h-full w-full rounded-2xl bg-[#F5FAFF] p-6 pb-16 font-poppins text-[#A3AED0] dark:bg-navy-700 3xl:mt-0 3xl:min-h-[730px] 3xl:w-[450px] 3xl:pb-6">
        <p>List of Milestones</p>
        {milestones?.results?.map((milestone) => (
          <MilestoneCard key={milestone.id} data={milestone} />
        ))}

        <button
          className="float-right mt-6 flex items-center gap-2 font-semibold text-brand-800 dark:text-navy-100"
          onClick={() => {
            navigate(`/higher-official/projects/${params.id}`);
          }}
        >
          <p>View all</p>
          <BsArrowRight size={20} />
        </button>
      </div>
    </div>
  );
}

export default ProjectDashboard;
