import { FiX } from "react-icons/fi";
import { IoInformationCircle } from "react-icons/io5";

function DeleteModal({ handleConfirm, handleCancel, id }) {
  return (
    <div
      id={id || "DeleteModal"}
      tabIndex="-1"
      className="fixed inset-0 z-40 hidden w-full items-center justify-center overflow-y-scroll bg-gray-900/50 dark:bg-gray-900/80"
    >
      <div className="relative w-full rounded-lg bg-white dark:bg-navy-800 md:w-[500px]">
        <button
          tabIndex="-1"
          className="absolute top-8 right-6"
          onClick={() => {
            window.document
              .getElementById(id || "DeleteModal")
              .classList.add("hidden");
          }}
        >
          <FiX className="mx-auto h-6 w-6 text-red-600" />
        </button>
        <div className="mt-5 py-10 px-16 text-center">
          <h4 className="text-3xl font-bold text-red-600 text-opacity-70 dark:text-gray-200 md:text-4xl">
            Are you sure?
          </h4>
          <p className="mt-3 flex items-center justify-center gap-1 text-sm text-[#667085] dark:text-gray-400 md:text-base">
            <IoInformationCircle className="h-5 w-5 text-[#667085]" />
            This action cannot be undone.
          </p>
          <div className="mt-12 flex justify-center gap-4">
            <button
              onClick={handleCancel}
              className="rounded-lg bg-[#5E5ADB] p-2 px-8 text-white"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              className="rounded-lg bg-[#F87171] p-2 px-10 text-white"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DeleteModal;
