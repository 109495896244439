import useAxios from "axios-hooks";
import StatusDropdown from "components/dropdown/StatusDropdown";
import StatusShow from "components/StatusShow";
import { useContext } from "react";
import { FaChevronDown } from "react-icons/fa";
import { MilestoneContext } from "views/admin/expert/ProjectDetails";

function MilestoneRowDetails({ showMilestone, showMileAdd, milestone }) {
  const { fetchMilestones } = useContext(MilestoneContext);

  const [{}, updateMile] = useAxios(
    {
      url: `/main_app/milestones/${milestone?.id}`,
      method: "PUT",
    },
    { manual: true }
  );

  const handleStatusChange = async (status) => {
    try {
      await updateMile({
        url: `/main_app/milestones/${milestone.id}/`,
        method: "PUT",
        data: { ...milestone, status },
      }).then(async () => {
        await fetchMilestones();
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={`${showMilestone ? "relative" : "hidden"} mt-2`}>
      <div className="items-start justify-between gap-10 rounded-lg bg-white py-2 px-2 text-sm text-gray-700 dark:bg-navy-900 dark:text-white md:flex md:px-4 md:text-base">
        {/* left side */}
        <div className="flex-grow">
          <div className="mt-4 flex items-center justify-between">
            <h1 className="w-full">Milestone Name</h1>
            <input
              type="text"
              placeholder="Milestone Name"
              className="w-full rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-900 md:w-[55%]"
              value={milestone?.milestone_name || "Not set"}
              disabled
            />
          </div>
          <div className="mt-4 flex flex-grow items-center justify-between">
            <h1 className="w-full">Milestone Description</h1>
            <textarea
              placeholder="Milestone Description"
              rows={3}
              className="w-full rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-900 md:w-[55%]"
              value={milestone?.milestone_description || "Not set"}
              disabled
            />
          </div>
          <div className="mt-4 flex flex-grow items-center justify-between">
            <h1 className="w-full">Milestone Budget</h1>
            <input
              type="text"
              placeholder="Milestone Budget"
              className="w-full rounded-lg bg-gray-50  py-4 px-2 dark:bg-navy-900 md:w-[55%]"
              value={milestone?.milestone_budget || "Not set"}
              disabled
            />
          </div>
        </div>
        {/* Right side */}
        <div className=" flex-grow">
          <div className="mt-4 flex flex-grow items-center justify-between">
            <h1 className="w-full">Milestone Output</h1>
            <textarea
              placeholder="Milestone Output"
              className="w-full rounded-lg bg-gray-50 py-4 px-2 md:w-[55%]"
              value={milestone?.project_output || "Not set"}
              disabled
            />
          </div>
          <div className="mt-4 flex flex-grow items-center justify-between">
            <h1 className="w-full">Start Date</h1>
            <input
              type="date"
              placeholder="Start Date"
              className="w-full rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-900 md:w-[55%]"
              value={milestone?.start_date}
              disabled
            />
          </div>
          <div className="mt-4 flex flex-grow items-center justify-between">
            <h1 className="w-full">End Date</h1>
            <input
              type="date"
              placeholder="Start Date"
              className="w-full rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-900 md:w-[55%]"
              value={milestone?.end_date}
              disabled
            />
          </div>
          <div className="mt-4 flex flex-grow items-center justify-between">
            <h1 className="w-full md:w-fit">Milestone Status</h1>
            <StatusDropdown
              pos={"-left-24"}
              button={
                <StatusShow status={milestone?.status || "not_started"}>
                  <FaChevronDown size={10} className="cursor-pointer" />
                </StatusShow>
              }
              onSubmit={(value) => handleStatusChange(value)}
            />
          </div>
        </div>
      </div>
      {showMileAdd && (
        <button className="absolute bottom-0 right-3 flex items-center gap-1 rounded-md bg-[#485FE5] py-2 px-3 text-white">
          <span>+</span> Add Activity
        </button>
      )}
    </div>
  );
}
export default MilestoneRowDetails;
