import AllProjects from "components/icons/AllProjects";
import PendingProjects from "components/icons/PendingProjects";
import { BsPlus } from "react-icons/bs";
import ProjectSidebar from "views/admin/ceo/components/ProjectSidebar";
import ComplexTable from "./components/ComplexTable";
import { useContext, useEffect, useState } from "react";
import SidebarModal from "components/popover/SidebarModal";
import { FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import AssignedProjectsList from "views/admin/team-leader/components/AssignedProjectsList";
import Paginate from "components/pagination";
import { TLRouteNameContext } from "layouts/teamLeader";
import { useLocalStorage } from "@uidotdev/usehooks";

const columnsDataComplex = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Project Name",
    accessor: "project_name",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
  },
  {
    Header: "End Date",
    accessor: "end_date",
  },

  {
    Header: "Priority",
    accessor: "project_priority",
  },
  {
    Header: "Region",
    accessor: "region_name",
  },

  {
    Header: "Project Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

function ProjectsList() {
  const path = window.location.pathname;
  const routePage = new URLSearchParams(window.location.search).get("page");
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedProject, setSelectedProject] = useState(null);
  const [page, setPage] = useState(routePage ? parseInt(routePage) : 1);
  const { setCurrentRoute, currentRoute } = useContext(TLRouteNameContext);
  const [user] = useLocalStorage("user");

  const [{ data: projects, loading, error }, fetchProjects] = useAxios(
    {
      url: "/main_app/projects/",
      params: { page: page || 1 },
      method: "GET",
    },
    { useCache: false }
  );
  const [{ loading: postLoading, error: postError }, post] = useAxios(
    {
      url: "/main_app/projects/",
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
    },
    { manual: true }
  );

  const tabs = [
    { title: "Created Projects", icon: <AllProjects /> },
    {
      title: "CEO Created Projects",
      icon: <PendingProjects />,
    },
    { title: "Assigned Projects", icon: <AllProjects /> },
  ];

  const activeColums =
    activeTab === 0
      ? columnsDataComplex.reduce((acc, item) => {
          if (item.Header === "Project Status") {
            return acc;
          }
          return [...acc, item];
        }, [])
      : columnsDataComplex;

  const tableData = projects?.results
    ?.filter((item) => {
      if (activeTab === 1) {
        return item["created_by"] !== user?.user;
      }
      if (activeTab === 2) {
        return item;
      }

      return true;
    })
    .map((item, index) => {
      if (activeTab === 0) {
        const newItem = { ...item, action: 0 };
        return newItem;
      }
      item["action"] = 1;
      return item;
    });

  const handleCreateProject = async (formData) => {
    try {
      const response = await post(
        {
          data: formData,
          method: selectedProject ? "PUT" : "POST",
          url: selectedProject
            ? `/main_app/projects/${selectedProject.id}/`
            : "/main_app/projects/",
        },
        { manual: true }
      );
      if (response.status === 201) {
        fetchProjects();
        setShowSidebar(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (path === "/team-leader/projects/create") {
      setShowSidebar(true);
      document.body.style.overflow = "hidden";
    }
    return () => {};
  }, [path]);

  useEffect(() => {
    if (routePage) {
      setPage(parseInt(routePage));
      // fetchProjects();
    } else {
      setPage(1);
    }
    return () => {};
  }, [routePage]);

  useEffect(() => {
    if (currentRoute !== "Projects List") {
      setCurrentRoute("Projects List");
    }
    return () => {};
    // eslint-disable-next-line
  }, [setCurrentRoute, currentRoute]);

  return (
    <div className="mt-5">
      {/* Add project drawer */}
      <SidebarModal
        bg={"bg-white"}
        closeBtn={
          <button
            id="close-drawer-navigation"
            tabIndex="-1"
            className="absolute right-0 top-1 z-50 h-20 w-20 rounded-full p-2 shadow-gray-800 drop-shadow-2xl transition-transform duration-150 dark:bg-gray-800 3xl:top-[48%] 3xl:right-[48%] 3xl:bg-white 3xl:shadow-2xl"
            onClick={() => {
              setShowSidebar(false);
              setSelectedProject(null);
              if (window.location.pathname.includes("create"))
                navigate("/team-leader/projects");
              document.body.style.overflow = "auto";
            }}
          >
            <FiX className="mx-auto h-10 w-10 text-gray-600" />
          </button>
        }
        isOpen={showSidebar}
        setIsOpen={setShowSidebar}
        content={
          <ProjectSidebar
            key={selectedProject ? selectedProject.id : "create"}
            handleCreateProject={handleCreateProject}
            loading={postLoading}
            error={postError}
            project={selectedProject}
          />
        }
      />
      <div className="mb-3 flex flex-wrap items-center justify-between">
        <div className="my-3 w-fit border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-navy-700 dark:text-gray-400">
          <ul className="-mb-px flex w-fit flex-wrap gap-5 lg:gap-16">
            {tabs.map((item, index) => (
              <li className="flex w-fit items-center" key={index}>
                <p
                  className={`flex cursor-pointer items-center gap-3 rounded-t-lg border-b-2 p-4 hover:border-navy-300 hover:text-gray-600  dark:hover:text-gray-300 ${
                    activeTab === index
                      ? "border-blue-600 fill-blue-600 text-blue-600 dark:border-navy-500 dark:text-blue-500"
                      : "border-none fill-[#84818A]"
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {item.icon}
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <button
          className="flex h-fit items-center gap-2 rounded-lg bg-[#485FE5] px-7 py-2 text-sm text-white"
          onClick={() => {
            setShowSidebar(true);
            document.body.style.overflow = "hidden";
          }}
        >
          <BsPlus size={25} /> Add existing project
        </button>
      </div>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {activeTab === 2 ? (
        <AssignedProjectsList
          projects={projects}
          page={page}
          setPage={setPage}
        />
      ) : (
        <>
          {!error && !loading && (
            <ComplexTable
              columnsData={activeColums}
              tableData={tableData || []}
              setSelectedProject={(project) => {
                setSelectedProject(project);
                setShowSidebar(true);
              }}
            />
          )}
          <Paginate
            count={projects?.count}
            page={page}
            setPage={(p) => {
              navigate(`/team-leader/projects?page=${p}`);
              setPage(p);
            }}
            next={() => {
              if (!projects?.next) {
                return;
              }
              navigate(`/team-leader/projects?page=${page + 1}`);
              setPage(page + 1);
            }}
            previous={() => {
              if (!projects?.previous) {
                return;
              }
              navigate(`/team-leader/projects?page=${page - 1}`);
              setPage(page - 1);
            }}
          />
        </>
      )}
    </div>
  );
}

export default ProjectsList;
