import Edit from "components/icons/Edit";
import {
  useProfileCreateMutation,
  useProfileUpdateMutation,
} from "features/auth/authApiSlice";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import dummy from "assets/img/avatars/dummy.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setProfile } from "features/auth/authSlice";
import Loading from "components/loading";
import Error from "components/error";

function EditProfile({ showProfileForm = false, setShowProfileForm }) {
  const [profileUpdate] = useProfileUpdateMutation();
  const [profileCreate] = useProfileCreateMutation();
  const [profilePicInput, setProfilePic] = useState(null);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [user_bio, setBio] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { email, firstName, lastName, bio, profilePic } = useSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setFirstName(firstName ? firstName : "");
    setLastName(lastName ? lastName : "");
    setBio(bio ? bio : "No bio");
    setProfilePic(profilePic ? profilePic : "");
  }, [firstName, lastName, bio, profilePic]);

  useEffect(() => {
    setError("");
  }, [first_name, last_name, user_bio, profilePicInput]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("bio", user_bio);
      formData.append("file", profilePicInput);

      const res = showProfileForm
        ? await profileCreate(formData).unwrap()
        : await profileUpdate(formData).unwrap();
      dispatch(setProfile(res));
      setLoading(false);
      showProfileForm
        ? navigate("/admin/home")
        : window.document.getElementById("profile").classList.toggle("hidden");
    } catch (err) {
      setLoading(false);
      console.error("erro console", err.data.file);
      setError(err.data.file[0]);
    }
  };

  return (
    <div
      className={`relative top-[20%] h-fit w-full cursor-default rounded-lg bg-white p-10 shadow dark:bg-navy-700 ${
        showProfileForm && "xl:left-[36%]"
      } xl:w-[500px]`}
    >
      {/* Add x button to close */}
      {!showProfileForm && (
        <div
          className="absolute top-10 right-5 cursor-pointer"
          onClick={() => {
            window.document
              .getElementById("profile")
              .classList.toggle("hidden");

            setShowProfileForm(true);
          }}
        >
          <FiX />
        </div>
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex items-end gap-3">
            <div className="relative w-fit cursor-pointer">
              <input
                type="file"
                className="hidden"
                id="profilePic"
                capture
                accept="image/*"
                onChange={(e) => {
                  console.log(e.target.files);
                  setProfilePic(e.target.files[0]);
                }}
              />

              <img
                src={
                  profilePicInput
                    ? typeof profilePicInput === "string"
                      ? profilePicInput
                      : URL.createObjectURL(profilePicInput)
                    : dummy
                }
                alt="Dummy Profile Pic"
                className="h-16 w-16 rounded-full"
                onClick={() => {
                  document.getElementById("profilePic").click();
                }}
              />
              <button
                className="absolute bottom-0.5 left-10 inline-block h-2 w-2 rounded-full bg-white p-3 text-white"
                onClick={() => {
                  document.getElementById("profilePic").click();
                }}
              >
                <Edit />
              </button>
            </div>
            <div>
              <h2 className="font-semibold">
                {first_name
                  ? last_name
                    ? first_name + " " + last_name
                    : first_name
                  : "Your name"}
              </h2>
              <p>{email}</p>
            </div>
          </div>

          <hr className="my-5" />

          {error && <Error error={error?.data} />}
          <form onSubmit={handleSubmit}>
            <div className="flex items-center gap-3 font-poppins">
              <label htmlFor="firstName" className="">
                First Name
              </label>
              <input
                id="firstName"
                type="text"
                className="flex h-12 flex-grow items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
                placeholder="First Name"
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <hr className="my-5" />
            <div className="flex items-center gap-3 font-poppins">
              <label htmlFor="lastName" className="">
                Last Name
              </label>
              <input
                id="lastName"
                type="text"
                className="flex h-12 flex-grow items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
                placeholder="Last Name"
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <hr className="my-5" />
            <div className="flex items-start gap-3 font-poppins">
              <label htmlFor="firstName" className="mt-4">
                Bio
              </label>
              <textarea
                id="bio"
                type="text"
                className="flex h-24 flex-grow items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
                placeholder="The concise explanation for leave, providing for their absence"
                rows="1"
                value={user_bio}
                onChange={(e) => setBio(e.target.value)}
              />
            </div>

            <hr className="my-5" />
            <button
              className="ml-auto flex items-center justify-center rounded-md bg-[#485FE5] py-2 px-6 font-poppins font-semibold text-white"
              type="submit"
            >
              Save Change
            </button>
          </form>
        </>
      )}
    </div>
  );
}

export default EditProfile;
