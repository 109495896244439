/* eslint-disable react-hooks/exhaustive-deps */
import AllProjects from "components/icons/AllProjects";
import CompletedProjects from "components/icons/CompletedProjects";
import PendingProjects from "components/icons/PendingProjects";
import ComplexTable from "./components/ComplexTable";
import { useContext, useEffect, useState } from "react";
import SearchIcon from "components/icons/SearchIcon";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import Paginate from "components/pagination";
import { ExpertRouteNameContext } from "layouts/expert";
import { useNavigate } from "react-router-dom";

const columnsDataComplex = [
  {
    Header: "No",
    accessor: "id",
  },
  {
    Header: "Project Name",
    accessor: "project_name",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
  },
  {
    Header: "End Date",
    accessor: "end_date",
  },

  {
    Header: "Priority",
    accessor: "project_priority",
  },
  {
    Header: "Region",
    accessor: "region_name",
  },

  {
    Header: "Project Plan Status",
    accessor: "execution_plan_status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

function ProjectsList() {
  const navigate = useNavigate();
  const routePage = new URLSearchParams(window.location.search).get("page");
  const [activeTab, setActiveTab] = useState(0);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(routePage ? parseInt(routePage) : 1);
  const { setCurrentRoute, currentRoute } = useContext(ExpertRouteNameContext);

  const [{ data: projects, loading, error }] = useAxios(
    {
      url: "/main_app/projects/",
      params: { page: page || 1 },
      method: "GET",
    },
    { useCache: false }
  );

  const tabs = [
    { title: "All Project Lists", icon: <AllProjects /> },
    {
      title: "Pending",
      icon: <PendingProjects />,
    },
    { title: "Approved", icon: <PendingProjects /> },
    { title: "Rejected", icon: <CompletedProjects /> },
  ];

  const activeColums =
    activeTab === 0
      ? columnsDataComplex.reduce((acc, item) => {
          if (item.Header === "Project Status") {
            return acc;
          }
          return [...acc, item];
        }, [])
      : columnsDataComplex;

  const handleSearch = () => {
    if (search?.length < 3) {
      return setTableData(projects?.results || []);
    }

    const value = search.toLowerCase();
    const result = projects?.results?.filter((item) => {
      return (
        item.project_name?.toLowerCase().includes(value) ||
        item.start_date?.toLowerCase().includes(value) ||
        item.end_date?.toLowerCase().includes(value) ||
        item.project_priority?.toLowerCase().includes(value) ||
        item.region_name?.toLowerCase().includes(value) ||
        item.execution_plan_status?.toLowerCase().includes(value)
      );
    });
    setTableData(result);
  };

  const fillterData = () => {
    setTableData(
      projects?.results
        ?.filter((item) => {
          if (activeTab === 1) {
            return item["execution_plan_status"] === "pending";
          }
          if (activeTab === 2) {
            return item["execution_plan_status"] === "approved";
          }
          if (activeTab === 3) {
            return item["execution_plan_status"] === "rejected";
          }

          return item;
        })
        .map((item, index) => {
          if (activeTab === 0) {
            const newItem = { ...item, action: 0 };
            return newItem;
          }
          item["action"] = 1;
          return item;
        })
    );
  };

  useEffect(() => {
    fillterData();
  }, [projects, activeTab]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    setCurrentRoute("Assigned Projects List");
    return () => {};
  }, [setCurrentRoute, currentRoute]);

  return (
    <div className="mt-5">
      <div className="mb-3 flex flex-col-reverse justify-between gap-5 xl:flex-row xl:items-center xl:gap-0">
        <div className="my-3 w-full  border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-navy-700 dark:text-gray-400 lg:w-fit">
          <ul className="-mb-px flex-wrap gap-5 lg:flex lg:w-fit 3xl:gap-16">
            {tabs.map((item, index) => (
              <li className="flex items-center lg:w-fit" key={index}>
                <p
                  className={`flex w-full cursor-pointer items-center gap-2 rounded-t-lg border-b-2 p-4 hover:border-navy-300 hover:text-gray-600  dark:hover:text-gray-300 ${
                    activeTab === index
                      ? "border-blue-600 fill-blue-600 text-blue-600 dark:border-navy-500 dark:text-blue-500"
                      : "border-none fill-[#84818A]"
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {item.icon}
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
        </div>

        <div className="w-full xl:w-1/3">
          <div className="flex h-14 w-full items-center justify-between rounded-lg border border-gray-200 text-gray-900 placeholder:text-gray-900 dark:text-white  xl:px-3">
            <SearchIcon className={"!md:mx-0 ml-1 min-w-[20px]"} />
            <input
              type="text"
              placeholder="Search"
              className="flex-grow rounded-lg border-none px-3 text-gray-900 placeholder:text-gray-900 focus:outline-none dark:bg-navy-900 dark:text-white dark:placeholder-gray-400"
              onChange={(e) => setSearch(e.target.value)}
            />

            {/* <div className="mr-1 flex w-fit cursor-pointer items-center gap-2 rounded-lg border p-2 font-bold dark:text-white">
            <BsFilter size={18} />
            <p>Filter</p>
          </div> */}
          </div>
          {search && search.length < 3 && (
            <p className="ml-2 text-red-500">Must be at least 3 characters</p>
          )}
        </div>
      </div>

      {error && <Error error={error} />}
      {loading && <Loading />}

      {!loading && projects?.results?.length > 0 && (
        <>
          <ComplexTable
            columnsData={activeColums}
            tableData={tableData || []}
          />
          <Paginate
            count={projects?.count}
            page={page}
            setPage={(p) => {
              navigate(`/expert/projects?page=${p}`);
              setPage(p);
            }}
            next={() => {
              if (!projects?.next) {
                return;
              }
              navigate(`/expert/projects?page=${page + 1}`);
              setPage(page + 1);
            }}
            previous={() => {
              if (!projects?.previous) {
                return;
              }
              navigate(`/expert/projects?page=${page - 1}`);
              setPage(page - 1);
            }}
          />
        </>
      )}
    </div>
  );
}

export default ProjectsList;
