import React, { createContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Footer from "components/footer/Footer";
import { FaTasks } from "react-icons/fa";
import ProjectDetails from "views/admin/team-leader/ProjectDetails";
import ProjectsList from "views/admin/team-leader/ProjectsList";
import ProjectDashboard from "views/admin/team-leader/ProjectDashboard";
import { teamLeaderRoutes } from "routes";
import { useWindowSize } from "@uidotdev/usehooks";
import ProjectsModal from "components/popover/ProjectsModal";
import Sidebar from "components/sidebar";

export const TLRouteNameContext = createContext(null);

export default function AdminTeamLeader(props) {
  const { ...rest } = props;
  const size = useWindowSize();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [currentRoute, setCurrentRoute] = React.useState(
    "Team leader Dashboard"
  );

  const teamLeaderHiddenRoutes = [
    ...teamLeaderRoutes,
    {
      name: "Project Details",
      layout: "/team-leader",
      icon: <FaTasks extra="h-6 w-6 fill-white" />,
      path: "projects/:id",
      component: <ProjectDetails />,
    },
    {
      name: "Project Dashboard",
      layout: "/team-leader",
      icon: <FaTasks extra="h-6 w-6 fill-white" />,
      path: "projects/create",
      component: <ProjectsList />,
    },
    {
      name: "Project 1",
      layout: "/team-leader",
      icon: <FaTasks extra="h-6 w-6 fill-white" />,
      path: "projects/:id/dashboard",
      component: <ProjectDashboard />,
    },
  ];

  React.useEffect(() => {
    if (size?.width < 1200) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [size]);

  React.useEffect(() => {
    getActiveRoute(teamLeaderHiddenRoutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/team-leader") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <TLRouteNameContext.Provider value={{ setCurrentRoute, currentRoute }}>
      <div className="flex h-full w-full">
        <ProjectsModal
          id={"ProjectsModalTeamLeader"}
          link={"team-leader"}
          showModal={showModal}
          setShowModal={setShowModal}
        />

        <Sidebar
          open={open}
          onClose={() => setOpen(false)}
          setShowModal={setShowModal}
          cRoutes={teamLeaderRoutes}
        />
        {/* Navbar & Main Content */}
        <div className="h-full w-full bg-white dark:!bg-navy-900">
          {/* Main Content */}
          <main
            className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
          >
            {/* Routes */}
            <div className="h-full">
              <Navbar
                onOpenSidenav={() => setOpen(true)}
                logoText={"MoLS Project Management System"}
                brandText={currentRoute}
                secondary={getActiveNavbar(teamLeaderHiddenRoutes)}
                {...rest}
              />
              <div className="mx-auto mb-auto h-full min-h-[84vh] p-2 pt-5 md:pr-2">
                <Routes>
                  {getRoutes(teamLeaderHiddenRoutes)}

                  <Route
                    path="/"
                    element={<Navigate to="/team-leader/home" replace />}
                  />
                </Routes>
              </div>
              <div className="p-3">
                <Footer />
              </div>
            </div>
          </main>
        </div>
      </div>
    </TLRouteNameContext.Provider>
  );
}
