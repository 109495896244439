import React from "react";

function TimeIcon() {
  return (
    <svg
      width="25"
      height="21"
      viewBox="0 0 21 21"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M20.8569 10.8213C20.8569 16.3453 16.3799 20.8213 10.8569 20.8213C5.33393 20.8213 0.856934 16.3453 0.856934 10.8213C0.856934 5.29929 5.33393 0.821289 10.8569 0.821289C16.3799 0.821289 20.8569 5.29929 20.8569 10.8213Z"
        fill="#F95D8F"
      />
      <path
        d="M14.4309 14.6361C14.2999 14.6361 14.1679 14.6021 14.0469 14.5311L10.1209 12.1891C9.89486 12.0531 9.75586 11.8081 9.75586 11.5441V6.49707C9.75586 6.08307 10.0919 5.74707 10.5059 5.74707C10.9199 5.74707 11.2559 6.08307 11.2559 6.49707V11.1181L14.8159 13.2411C15.1709 13.4541 15.2879 13.9141 15.0759 14.2701C14.9349 14.5051 14.6859 14.6361 14.4309 14.6361Z"
        fill="#F95D8F"
      />
    </svg>
  );
}

export default TimeIcon;
