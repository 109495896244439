import { statusOptions } from "components/dropdown/StatusDropdown";
import { useEffect, useState } from "react";

function StatusShow({ status, children, extra }) {
  const [color, setColor] = useState("bg-[#C97A20]");

  const findColor = (status) => {
    const found = statusOptions.find((opt) => opt.title === status)?.color;
    if (found) {
      setColor(found);
    } else {
      setColor("#C97A20");
    }
  };

  useEffect(() => {
    findColor(status);
  }, [status]);

  return (
    <div
      className={`flex w-full items-center gap-2 rounded-lg pb-3 md:w-fit md:px-4 md:pb-0 ${extra}`}
    >
      <div className="my-2 flex items-center gap-2 rounded-lg bg-[#EDEDFC] p-1 ">
        <div
          className={`ml-1 h-2 w-2 rounded-sm `}
          style={{ background: color }}
        />
        <p className={`mr-2 `} style={{ color: color }}>
          {status?.toUpperCase() || "N/A"}
        </p>
      </div>
      {children}
    </div>
  );
}
export default StatusShow;
