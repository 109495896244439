import logoFull from "assets/img/layout/MoLS-Logo-Full.png";

const FreeCard = () => {
  return (
    <div className="relative mt-14 flex max-h-[220px] max-w-[230px] justify-center rounded-[20px] bg-gray-50 pb-4 dark:bg-navy-650">
      <div className="absolute -top-12 flex h-20 w-20 items-center justify-center rounded-full bg-gray-50 dark:bg-navy-800">
        <svg
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="bg-gray-10 rounded-full drop-shadow-glow"
        >
          <path
            d="M19.2097 6.35952C18.1697 4.25952 16.1597 2.70952 13.8297 2.19952C11.3897 1.65952 8.88973 2.23952 6.97973 3.77952C5.05973 5.30952 3.96973 7.59952 3.96973 10.0495C3.96973 12.6395 5.51973 15.3495 7.85973 16.9195V17.7495C7.84973 18.0295 7.83973 18.4595 8.17973 18.8095C8.52973 19.1695 9.04973 19.2095 9.45973 19.2095H14.5897C15.1297 19.2095 15.5397 19.0595 15.8197 18.7795C16.1997 18.3895 16.1897 17.8895 16.1797 17.6195V16.9195C19.2797 14.8295 21.2297 10.4195 19.2097 6.35952Z"
            fill="#FBCB18"
          />
          <path
            d="M15.2602 22.0004C15.2002 22.0004 15.1302 21.9904 15.0702 21.9704C13.0602 21.4004 10.9502 21.4004 8.94015 21.9704C8.57015 22.0704 8.18015 21.8604 8.08015 21.4904C7.97015 21.1204 8.19015 20.7304 8.56015 20.6304C10.8202 19.9904 13.2002 19.9904 15.4602 20.6304C15.8302 20.7404 16.0502 21.1204 15.9402 21.4904C15.8402 21.8004 15.5602 22.0004 15.2602 22.0004Z"
            fill="#FBCB18"
          />
        </svg>
      </div>

      <div className="mt-10 flex h-fit flex-col items-center">
        <p className="text-lg font-bold ">Powered by</p>
        <p className="mt-1 px-4 text-center text-xs ">
          This project managements application is powered by Ministry 0f Labor
          and Skills 2024
        </p>

        <div className="mx-4 mb-2 mt-5 rounded-sm">
          <img src={logoFull} alt="logo" className="h-10 w-48" />
        </div>
      </div>
    </div>
  );
};

export default FreeCard;
