import { Routes, Route, Navigate } from "react-router-dom";

import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import VerifyAcc from "views/auth/VerifyEmail";
import EmailSent from "views/auth/EmailSent";
import RequireAuth from "features/auth/RequireAuth";
import AdminCeo from "layouts/ceo";
import PasswordReset from "views/auth/PasswordReset";
import PasswordResetConfirm from "views/auth/PasswordResetConfirm";
import AdminExpert from "layouts/expert";
import AdminTeamLeader from "layouts/teamLeader";
import HOLayout from "layouts/HO";

const App = () => {
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route element={<RequireAuth />}>
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="ceo/*" element={<AdminCeo />} />
        <Route path="expert/*" element={<AdminExpert />} />
        <Route path="team-leader/*" element={<AdminTeamLeader />} />
        <Route path="higher-official/*" element={<HOLayout />} />
      </Route>
      <Route path="rtl/*" element={<RtlLayout />} />
      <Route path="/" element={<Navigate to="/auth" replace />} />
      <Route path="auth/verify-email/*" element={<EmailSent />} />
      <Route path="activate/*" element={<VerifyAcc />} />
      <Route path="pass/reset/" element={<PasswordReset />} />
      <Route path="email/reset/confirm/*" element={<PasswordResetConfirm />} />
    </Routes>
  );
};

export default App;
