import DropdownControlled from "components/dropdown/DropdownControlled";
import { statusOptions } from "components/dropdown/StatusDropdown";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";

function StatusDropdown({ button, pos, onSubmit, status }) {
  const [selectedStatus, setSelectedStatus] = useState("not_started");
  const [openWrapper, setOpenWrapper] = useState(false);

  useEffect(() => {
    if (status) {
      setSelectedStatus(status);
    }
  }, [status]);

  return (
    <DropdownControlled
      openWrapper={openWrapper}
      setOpenWrapper={setOpenWrapper}
      id="status-dropdown"
      classNames={`pt-2 top-12 ${
        pos ? pos : "-left-18"
      } w-max shadow-xl rounded-lg`}
      button={button ? button : <FaChevronDown className="cursor-pointer" />}
      children={
        <div className="min-w-[250px] rounded-xl bg-white p-4 text-sm">
          <h1 className="mb-3 text-start font-semibold">Change status</h1>
          <ul className="flex flex-col gap-1 border-b">
            {statusOptions.map((status, index) => (
              <li
                key={index}
                className={`cursor-pointer ${
                  selectedStatus === status.title
                    ? "flex w-full items-center justify-between rounded-lg bg-[#5E5ADB] px-2 text-white"
                    : "hover:bg-gray-50"
                }`}
                onClick={() => setSelectedStatus(status.title)}
              >
                <div className="my-2 flex  items-center gap-2 ">
                  <div
                    className={`ml-1 h-3 w-3 rounded-sm `}
                    style={{ background: status.color }}
                  />
                  <p className="mr-2">{status.title.toUpperCase()}</p>
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-2 flex w-full items-center justify-end gap-2">
            <div className="cursor-pointer rounded-lg p-2 px-4 hover:bg-gray-50 dark:text-gray-800">
              Cancel
            </div>
            <div
              className="cursor-pointer rounded-lg p-2 px-4 text-[#5E5ADB] hover:bg-navy-50"
              onClick={() => {
                setOpenWrapper(false);
                onSubmit(selectedStatus);
              }}
            >
              Apply
            </div>
          </div>
        </div>
      }
    />
  );
}
export default StatusDropdown;
