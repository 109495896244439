import Card from "components/card";

const Widget = ({ icon, title, subtitle, textColor, fill }) => {
  return (
    <Card extra="shadow p-3 bg-[#FCFCFC] rounded-md 3xl:px-5">
      <div className="flex justify-between">
        <div className="mr-5 flex flex-col gap-2 rounded-md">
          <span
            className={`flex items-center ${
              fill ? fill : "fill-white"
            } dark:text-white`}
          >
            {icon}
          </span>
        </div>
        {/* <p className="font-poppins text-sm text-gray-700 dark:text-gray-300 3xl:text-base">
          <span className={`${textColor}`}>10%</span> vs last month
        </p> */}
      </div>

      <div className="mt-2 flex w-auto flex-col justify-start">
        <h4 className="my-2 text-sm text-[#787486] dark:text-white">
          Total {title}
        </h4>
        <h4
          className={`text-lg font-bold text-[#787486] dark:text-white ${textColor}`}
        >
          {subtitle}
        </h4>
      </div>
    </Card>
  );
};

export default Widget;
