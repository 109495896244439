import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import logo from "assets/img/layout/MoLS-logo.png";
import LeftArrow from "components/icons/LeftArrow";
import SidebarLinks from "components/sidebar/components/Links";
import { BsChevronDoubleLeft } from "react-icons/bs";
import useAxios from "axios-hooks";
import { Link } from "react-router-dom";
import { Suspense } from "react";
import Loading from "components/loading";
import { IoArrowForward } from "react-icons/io5";
import { higherOfficialRoutes } from "routes";

const Sidebar = ({ open, onClose, setShowModal }) => {
  const path = window.location.pathname;

  const [{ data: projects }] = useAxios(
    {
      url: "/main_app/projects/",
      params: { page: 1, page_size: 3 },
      method: "GET",
    },
    { useCache: false }
  );
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-12 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <BsChevronDoubleLeft size={20} color="#787486" />
      </span>

      <div
        className={`ml-[36px] mr-12 mt-[45px] flex items-center justify-between`}
      >
        <div className="mt-1 ml-1 flex h-2.5 items-center gap-2 font-poppins text-xl  font-semibold text-[#787486] dark:text-white">
          <img src={logo} className="w-11" alt="logo" />
          Project <span className="font-semibold">M.</span>
        </div>
        <LeftArrow className="invisible ml-8" />
      </div>
      <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <SidebarLinks routes={higherOfficialRoutes} />
        {/* Projects list */}
        <div className="mt-10 px-10">
          <hr className="my-4" />
          <div className="mr-5 ml-2 flex items-center justify-between">
            <h1 className="my-4 ml-2 font-poppins text-sm font-semibold uppercase text-[#787486]">
              My Projects
            </h1>
          </div>
          <Suspense fallback={<Loading />}>
            <ul>
              {projects &&
                [...projects?.results]?.splice(0, 3)?.map((item, i) => {
                  const active = path.includes(
                    `/higher-official/projects/${item.id}`
                  );
                  return (
                    <Link
                      key={i}
                      to={`/higher-official/projects/${item.id}/dashboard`}
                      className={`my-1 flex min-w-[200px] items-center gap-2 rounded-lg py-2 px-4 font-poppins hover:underline ${
                        active ? "bg-brand-50 dark:bg-brand-200" : ""
                      }`}
                    >
                      <div
                        className="h-2 w-2 rounded-full"
                        style={{
                          backgroundColor: item.id % 2 === 0 ? "red" : "blue",
                        }}
                      />
                      <p
                        className={`${
                          active ? "text-brand-500" : "text-gray-700"
                        }`}
                      >
                        {item.project_name}
                      </p>
                    </Link>
                  );
                })}
              {projects && (
                <li
                  className="my-1 flex min-w-[200px] cursor-pointer items-center gap-2 rounded-lg py-2 px-4 font-poppins hover:underline"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <IoArrowForward className="text-gray-700" />
                  <p className="text-gray-700">View all projects</p>
                </li>
              )}
            </ul>
          </Suspense>
        </div>
      </ul>

      {/* Free Horizon Card */}
      <div className="flex justify-center">
        <SidebarCard />
      </div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
