import { useState, useEffect } from "react";
import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { Link, useNavigate } from "react-router-dom";
import PasswordInputField from "components/fields/PasswordInputField";
import { useDispatch } from "react-redux";
import { setCredentials, setProfile } from "features/auth/authSlice";
import {
  useLoginMutation,
  useUserProfileMutation,
} from "features/auth/authApiSlice";
import { MdError } from "react-icons/md";
import EditProfile from "components/navbar/EditProfile";
import Loading from "components/loading";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showProfileForm, setShowProfileForm] = useState(false);
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const [userProfile] = useUserProfileMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    setErrMsg("");

    return () => {};
  }, [email, password]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await login({ email, password }).unwrap();
      const { access, refresh } = data;
      dispatch(
        setCredentials({ email, accessToken: access, refreshToken: refresh })
      );
      const profile = await userProfile().unwrap();
      if (profile?.results?.length === 0) {
        setShowProfileForm(true);
        setIsLoading(false);
        return;
      }
      dispatch(setProfile(profile.results[0]));
      setEmail("");
      setPassword("");
      setTimeout(() => navigate("/expert/projects"), 500);
    } catch (err) {
      setIsLoading(false);
      setErrMsg(err?.data?.detail || err?.error);
    }
  };

  return (
    <div className="mt-8 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {showProfileForm && (
        <div className="absolute inset-0 z-50 h-screen w-screen bg-gray-900/50 dark:bg-gray-900/80">
          <EditProfile
            setShowProfileForm={setShowProfileForm}
            showProfileForm={showProfileForm}
          />
        </div>
      )}
      {/* Sign in section */}
      <div className="w-full max-w-full flex-col items-center md:mt-[10vh] md:pl-4 lg:pl-0 xl:mx-44">
        <h4 className="mb-2.5 font-poppins text-5xl font-bold text-blue-700 dark:text-white">
          Login
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-800 dark:text-gray-300">
          Enter your email, phone number and password to login!
        </p>
        {isLoading ? (
          <Loading />
        ) : (
          <form onSubmit={handleLogin}>
            {errMsg && (
              <div className="my-2 flex w-full items-center gap-2 rounded-lg bg-red-400 py-3 px-3">
                <MdError color="white" />
                <p className="text-sm text-white">{errMsg}</p>
              </div>
            )}
            {/* Email */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Email Address"
              placeholder="example@gmail.com"
              id="email"
              type="text"
              value={email}
              onChange={setEmail}
              required
            />

            {/* Password */}
            <PasswordInputField
              variant="auth"
              extra="mb-3"
              label="Password"
              placeholder="Min. 8 characters"
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <p className="mb-9 ml-1 pl-3 text-sm text-gray-600 dark:text-gray-300">
              Must have at least two characters, and don't start with number
            </p>

            {/* Checkbox */}
            <div className="mb-4 flex items-center justify-between px-2">
              <div className="flex items-center">
                <Checkbox defaultChecked={true} />
                <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                  Keep me logged In
                </p>
              </div>
              <Link
                className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                to="/pass/reset"
              >
                Forgot Password?
              </Link>
            </div>
            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              type="submit"
            >
              Sign In
            </button>
            <div className="mt-4 text-center">
              <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                Not registered yet?
              </span>
              <Link
                to="/auth/sign-up"
                className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              >
                Create an account
              </Link>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
