import React from "react";

function LeftArrow({ className }) {
  return (
    <svg
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.5004 17.2253C18.3421 17.2253 18.1837 17.1669 18.0587 17.0419L12.6254 11.6086C11.7421 10.7253 11.7421 9.27526 12.6254 8.39193L18.0587 2.95859C18.3004 2.71693 18.7004 2.71693 18.9421 2.95859C19.1837 3.20026 19.1837 3.60026 18.9421 3.84193L13.5087 9.27526C13.1087 9.67526 13.1087 10.3253 13.5087 10.7253L18.9421 16.1586C19.1837 16.4003 19.1837 16.8003 18.9421 17.0419C18.8171 17.1586 18.6587 17.2253 18.5004 17.2253Z"
        fill="#787486"
      />
      <path
        d="M12.5004 17.2253C12.3421 17.2253 12.1837 17.1669 12.0587 17.0419L6.62539 11.6086C5.74206 10.7253 5.74206 9.27526 6.62539 8.39193L12.0587 2.95859C12.3004 2.71693 12.7004 2.71693 12.9421 2.95859C13.1837 3.20026 13.1837 3.60026 12.9421 3.84193L7.50872 9.27526C7.10872 9.67526 7.10872 10.3253 7.50872 10.7253L12.9421 16.1586C13.1837 16.4003 13.1837 16.8003 12.9421 17.0419C12.8171 17.1586 12.6587 17.2253 12.5004 17.2253Z"
        fill="#787486"
      />
    </svg>
  );
}

export default LeftArrow;
