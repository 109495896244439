function BaseDataIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 2.25H6C5.60218 2.25 5.22064 2.40804 4.93934 2.68934C4.65804 2.97064 4.5 3.35218 4.5 3.75V20.25C4.5 20.6478 4.65804 21.0294 4.93934 21.3107C5.22064 21.592 5.60218 21.75 6 21.75H18C18.3978 21.75 18.7794 21.592 19.0607 21.3107C19.342 21.0294 19.5 20.6478 19.5 20.25V3.75C19.5 3.35218 19.342 2.97064 19.0607 2.68934C18.7794 2.40804 18.3978 2.25 18 2.25ZM18 3.75V8.25H6V3.75H18ZM6 14.25V9.75H18V14.25H6ZM6 20.25V15.75H18V20.25H6Z"
        // fill="#787486"
      />
      <path
        d="M8.25 6.75C8.66421 6.75 9 6.41421 9 6C9 5.58579 8.66421 5.25 8.25 5.25C7.83579 5.25 7.5 5.58579 7.5 6C7.5 6.41421 7.83579 6.75 8.25 6.75Z"
        // fill="#787486"
      />
      <path
        d="M8.25 12.75C8.66421 12.75 9 12.4142 9 12C9 11.5858 8.66421 11.25 8.25 11.25C7.83579 11.25 7.5 11.5858 7.5 12C7.5 12.4142 7.83579 12.75 8.25 12.75Z"
        // fill="#787486"
      />
      <path
        d="M8.25 18.75C8.66421 18.75 9 18.4142 9 18C9 17.5858 8.66421 17.25 8.25 17.25C7.83579 17.25 7.5 17.5858 7.5 18C7.5 18.4142 7.83579 18.75 8.25 18.75Z"
        // fill="#787486"
      />
    </svg>
  );
}
export default BaseDataIcon;
