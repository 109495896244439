import React from "react";

function Project({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 7C12.5523 7 13 6.55228 13 6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6C11 6.55228 11.4477 7 12 7Z"
        // fill="#485FE5"
      />
      <path
        d="M6 17H18V19H6V17ZM10 11.83L12.792 14.624L16.724 10.689L18 12V8H14L15.31 9.275L12.791 11.794L10 9L6 13L7.414 14.414L10 11.83Z"
        // fill="#485FE5"
      />
      <path
        d="M19 3.00007H15.702C15.603 2.85282 15.4961 2.71096 15.382 2.57507L15.372 2.56307C14.637 1.70764 13.6034 1.16475 12.482 1.04507C12.1635 0.984977 11.8365 0.984977 11.518 1.04507C10.3966 1.16475 9.36298 1.70764 8.628 2.56307L8.618 2.57507C8.5039 2.71063 8.39708 2.85216 8.298 2.99907V3.00007H5C4.20459 3.00086 3.44199 3.31719 2.87956 3.87963C2.31712 4.44206 2.00079 5.20466 2 6.00007V20.0001C2.00079 20.7955 2.31712 21.5581 2.87956 22.1205C3.44199 22.6829 4.20459 22.9993 5 23.0001H19C19.7954 22.9993 20.558 22.6829 21.1204 22.1205C21.6829 21.5581 21.9992 20.7955 22 20.0001V6.00007C21.9992 5.20466 21.6829 4.44206 21.1204 3.87963C20.558 3.31719 19.7954 3.00086 19 3.00007ZM20 20.0001C20 20.2653 19.8946 20.5196 19.7071 20.7072C19.5196 20.8947 19.2652 21.0001 19 21.0001H5C4.73478 21.0001 4.48043 20.8947 4.29289 20.7072C4.10536 20.5196 4 20.2653 4 20.0001V6.00007C4 5.73485 4.10536 5.4805 4.29289 5.29296C4.48043 5.10543 4.73478 5.00007 5 5.00007H9.55C9.66476 4.43492 9.97136 3.92683 10.4179 3.56188C10.8644 3.19693 11.4233 2.99756 12 2.99756C12.5767 2.99756 13.1356 3.19693 13.5821 3.56188C14.0286 3.92683 14.3352 4.43492 14.45 5.00007H19C19.2652 5.00007 19.5196 5.10543 19.7071 5.29296C19.8946 5.4805 20 5.73485 20 6.00007V20.0001Z"
        // fill="#485FE5"
      />
    </svg>
  );
}

export default Project;
