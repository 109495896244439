import React from "react";

function BackIcon({ className, onClick }) {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path d="M1.99994 5.99997L1.29294 6.70697L0.585938 5.99997L1.29294 5.29297L1.99994 5.99997ZM18.9999 14C18.9999 14.2652 18.8946 14.5195 18.707 14.7071C18.5195 14.8946 18.2652 15 17.9999 15C17.7347 15 17.4804 14.8946 17.2928 14.7071C17.1053 14.5195 16.9999 14.2652 16.9999 14H18.9999ZM6.29294 11.707L1.29294 6.70697L2.70694 5.29297L7.70694 10.293L6.29294 11.707ZM1.29294 5.29297L6.29294 0.292969L7.70694 1.70697L2.70694 6.70697L1.29294 5.29297ZM1.99994 4.99997H11.9999V6.99997H1.99994V4.99997ZM18.9999 12V14H16.9999V12H18.9999ZM11.9999 4.99997C13.8565 4.99997 15.6369 5.73747 16.9497 7.05022C18.2624 8.36298 18.9999 10.1435 18.9999 12H16.9999C16.9999 10.6739 16.4732 9.40212 15.5355 8.46443C14.5978 7.52675 13.326 6.99997 11.9999 6.99997V4.99997Z" />
    </svg>
  );
}

export default BackIcon;
