export const colors = [
  "#b3005e",
  "#070e5c",
  "#530365",
  "#860065",
  "#d62550",
  "#ef503e",
  "#fd7b27",
  "#ffa600",
];
