function MailSent() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.33366 29.6663C3.32533 29.6663 2.46183 29.307 1.74316 28.5883C1.0245 27.8697 0.665773 27.0068 0.666995 25.9997V3.99968C0.666995 2.99134 1.02633 2.12784 1.745 1.40918C2.46366 0.690511 3.32655 0.331789 4.33366 0.333011H33.667C34.6753 0.333011 35.5388 0.692344 36.2575 1.41101C36.9762 2.12968 37.3349 2.99257 37.3337 3.99968V25.9997C37.3337 27.008 36.9743 27.8715 36.2557 28.5902C35.537 29.3088 34.6741 29.6676 33.667 29.6663H4.33366ZM4.33366 7.66634H33.667V3.99968H4.33366V7.66634ZM17.0753 23.3413L27.4337 12.983L24.7753 10.3247L17.0753 18.0247L13.2253 14.1747L10.567 16.833L17.0753 23.3413Z"
        fill="#485FE5"
      />
    </svg>
  );
}
export default MailSent;
