import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";
const ComplexTable = (props) => {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto "}>
      <div className="mt-2 h-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full p-4 font-poppins">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="ml-5 border-b border-gray-200 bg-[#F5FAFF] p-5 pl-3 pb-[10px] text-start dark:!border-navy-900 dark:bg-navy-900"
                  >
                    <p className="pb-2 text-xs tracking-wide text-gray-700 dark:text-white">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, i) => {
                    let data = "";
                    if (cell.column.Header === "Project Name") {
                      data = (
                        <p className="pl-3 text-sm  text-navy-900 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "No") {
                      data = (
                        <p className="pl-3 text-sm text-navy-900 dark:text-white">
                          {Number(index + 1).toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                          })}
                        </p>
                      );
                    } else if (cell.column.Header === "Priority") {
                      data =
                        cell.value === "low" ? (
                          <div className="flex w-fit items-center gap-2 rounded-2xl bg-[#ECFDF3] p-0.5 px-3">
                            <div className="h-2 w-2 rounded-lg bg-[#12B76A]" />
                            <p className="text-sm text-[#027A48] ">
                              {cell.value}
                            </p>
                          </div>
                        ) : cell.value === "high" ? (
                          <div className="flex w-fit items-center gap-2 rounded-2xl bg-[#FFF2EA] p-0.5 px-3">
                            <div className="h-2 w-2 rounded-lg bg-[#F15046]" />
                            <p className="text-sm text-[#F15046] ">
                              {cell.value}
                            </p>
                          </div>
                        ) : cell.value === "medium" ? (
                          <div className="flex w-fit items-center gap-2 rounded-2xl bg-[#FFF2EA] p-0.5 px-3">
                            <div className="h-2 w-2 rounded-lg bg-[#FFB342]" />
                            <p className="text-sm text-[#FFB342] ">
                              {cell.value}
                            </p>
                          </div>
                        ) : null;
                    } else if (cell.column.Header === "Project Plan Status") {
                      data = (
                        <div className="ml-5 flex w-fit rounded-full">
                          {cell.value === "approved" ? (
                            <div className="flex items-center justify-center gap-2 rounded-lg bg-[#E1FCEF] py-0.5 px-3">
                              <div className="h-2 w-2 rounded-sm bg-[#38A06C]" />
                              <p className="text-sm capitalize  text-[#14804A]">
                                {cell.value}
                              </p>
                            </div>
                          ) : cell.value === "rejected" ? (
                            <div className="flex items-center gap-2 rounded-lg bg-[#FFEEAE] py-0.5 px-3">
                              <div className="h-2 w-2 rounded-sm bg-[#A8870C]" />
                              <p className="text-sm  capitalize  text-[#A8870C]">
                                {cell.value}
                              </p>
                            </div>
                          ) : cell.value === "pending" ? (
                            <div className="flex w-fit items-center gap-2 rounded-2xl bg-[#FFF2EA] p-0.5 px-3">
                              <div className="h-2 w-2 rounded-lg bg-[#FFB342] capitalize" />
                              <p className="text-sm text-[#FFB342] ">
                                {cell.value}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      );
                    } else if (cell.column.Header === "Start Date") {
                      data = (
                        <p className="w-[102px] rounded-2xl bg-[#F5F6F7] py-1 px-3 text-sm text-navy-900 dark:bg-navy-900 dark:text-white md:w-fit">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "End Date") {
                      data = (
                        <p className="w-[102px] rounded-2xl bg-[#F5F6F7] py-1 px-3 text-sm text-navy-900 dark:bg-navy-900 dark:text-white md:w-fit">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "Region") {
                      data = (
                        <p className="flex w-fit max-w-[230px] flex-wrap py-1 text-sm text-navy-900 dark:text-white">
                          {cell.value.map((item, i) => (
                            <span
                              key={i}
                              className="ml-0.5 mb-1 w-fit rounded-full bg-gray-200 px-2 py-0.5 text-xs"
                            >
                              {item}
                            </span>
                          ))}
                        </p>
                      );
                    } else if (cell.column.Header === "Action") {
                      data = (
                        <Link
                          to={`/expert/projects/${cell.row.original.id}`}
                          className="-mr-5 truncate rounded-lg bg-brand-500 py-2 px-3 text-xs text-white"
                        >
                          Open Project
                        </Link>
                      );
                    }
                    return (
                      <td
                        className="border-b px-1 pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={i}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ComplexTable;
