/* eslint-disable react-hooks/exhaustive-deps */
import { FiX } from "react-icons/fi";
import StatusDropdown from "components/dropdown/StatusDropdown";
import { FaChevronDown } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import { MilestoneContext } from "views/admin/expert/ProjectDetails";
import StatusShow from "components/StatusShow";

function SubActivityModal({ activity, update }) {
  const [activityName, setActivityName] = useState("");
  const [activityDesc, setActivityDesc] = useState("");
  const [activityStatus, setActivityStatus] = useState("not started");
  const [activityStart, setActivityStart] = useState("");
  const [activityEnd, setActivityEnd] = useState("");
  const [activityBudget, setActivityBudget] = useState("");
  const { fetchMilestones } = useContext(MilestoneContext);

  const [{ loading, error }, postActivity] = useAxios(
    {
      url: "/main_app/activities/",
      method: update ? "PUT" : "POST",
    },
    { manual: true }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!activity.project_milestone && !activity?.id) return;
    let success = false;
    const data = {
      activity_name: activityName,
      activity_description: activityDesc,
      activity_status: activityStatus,
      start_date: activityStart,
      end_date: activityEnd,
      activity_budget: activityBudget,
      project_milestone: activity.project_milestone,
      parent_activity: activity?.id,
    };
    try {
      await postActivity({
        url: update
          ? `/main_app/activities/${activity.id}/`
          : "/main_app/activities/",
        data: data,
      }).then(async () => {
        await fetchMilestones();
        window.document
          .getElementById(`SubtaskModalForm${activity.id}`)
          .classList.replace("flex", "hidden");
      });
    } catch (error) {
      console.log(error);
      success = false;
    }

    return success;
  };

  useEffect(() => {
    if (update && activity) {
      setActivityName(activity.activity_name);
      setActivityDesc(activity.activity_description);
      setActivityStatus(activity.status);
      setActivityStart(activity.start_date);
      setActivityEnd(activity.end_date);
      setActivityBudget(activity.activity_budget);
    } else {
      setActivityName("");
      setActivityDesc("");
      setActivityStatus("not started");
      setActivityStart("");
      setActivityEnd("");
      setActivityBudget("");
    }
  }, [update, activity]);

  return (
    <div
      id={`SubtaskModalForm${activity.id}`}
      tabIndex="-1"
      className="fixed inset-0 z-40 hidden w-full items-center justify-center overflow-y-scroll bg-gray-900/50 dark:bg-gray-900/80"
    >
      <div className="relative w-[900px] rounded-lg bg-white dark:bg-navy-800">
        <button
          tabIndex="-1"
          className="absolute top-8 right-6"
          onClick={() => {
            window.document
              .getElementById(`SubtaskModalForm${activity.id}`)
              .classList.replace("flex", "hidden");
          }}
        >
          <FiX className="mx-auto h-6 w-6 text-red-600" />
        </button>
        <div className="flex flex-col px-6 pt-8 text-start lg:px-12">
          <h1 className="text-3xl font-semibold text-gray-900">
            {update ? "Update Sub activity" : "Add Sub activity"}
          </h1>
          <p className=" text-gray-700">
            {activity?.activity_name || "activity name"}
          </p>
        </div>
        {error && <Error error={error} />}
        {loading && <Loading />}
        {!loading && (
          <div className="flex flex-col px-6 pb-6 text-start lg:px-12">
            <form
              id={`SubtaskForm${activity.id}`}
              onSubmit={handleSubmit}
              className="flex flex-col items-stretch justify-between rounded-lg bg-white py-2 text-start text-gray-700 lg:flex-row lg:gap-10"
            >
              {/* left side */}
              <div className="flex-grow">
                <div className="mt-4 flex items-center justify-between">
                  <div>
                    <h1>
                      Activity Name <span className="text-red-700">*</span>
                    </h1>
                    <p className="text-xs text-gray-500">Write a name</p>
                  </div>
                  <input
                    id="activityName"
                    type="text"
                    placeholder="Activity Name"
                    className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
                    onChange={(e) => setActivityName(e.target.value)}
                    value={activityName}
                    required
                  />
                </div>
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1>Activity Description</h1>
                    <p className="text-xs text-gray-500">Short Description</p>
                  </div>
                  <textarea
                    id="activityDescription"
                    placeholder="Activity Description"
                    className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
                    onChange={(e) => setActivityDesc(e.target.value)}
                    value={activityDesc}
                  />
                </div>
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1 className="">
                      Activity Budget <span className="text-red-700">*</span>
                    </h1>
                    <p className="text-xs text-gray-500">Write a budget</p>
                  </div>
                  <input
                    id="activityBudget"
                    type="number"
                    pattern="[0-9]*"
                    placeholder="Activity Budget"
                    className="w-[55%] rounded-lg  bg-gray-50 py-4 px-2"
                    onChange={(e) => setActivityBudget(e.target.value)}
                    value={activityBudget}
                    required
                  />
                </div>
              </div>
              {/* Right side */}
              <div className=" flex-grow">
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1>
                      Start Date <span className="text-red-700">*</span>
                    </h1>
                    <p className="text-xs text-gray-500">Type Date</p>
                  </div>
                  <input
                    id="activityStartDate"
                    type="date"
                    placeholder="Start Date"
                    className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
                    onChange={(e) => setActivityStart(e.target.value)}
                    value={activityStart}
                    required
                  />
                </div>
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1>
                      End Date <span className="text-red-700">*</span>
                    </h1>
                    <p className="text-xs text-gray-500">Type Date</p>
                  </div>
                  <input
                    id="activityEndDate"
                    type="date"
                    placeholder="Start Date"
                    className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
                    onChange={(e) => setActivityEnd(e.target.value)}
                    value={activityEnd}
                    required
                  />
                </div>
                <div className="mt-4 flex flex-grow items-center justify-between gap-[23%] ">
                  <div>
                    <h1>
                      Status <span className="text-red-700">*</span>
                    </h1>
                    <p className="text-xs text-gray-500">Choose status</p>
                  </div>
                  <StatusDropdown
                    pos={"-left-24"}
                    button={
                      <StatusShow status={activityStatus}>
                        <FaChevronDown size={10} className="cursor-pointer" />
                      </StatusShow>
                    }
                    onSubmit={(value) => setActivityStatus(value)}
                  />
                </div>
              </div>
            </form>
            <button
              type="submit"
              form={`SubtaskForm${activity.id}`}
              className="self-end rounded-lg bg-blue-600 px-10 py-3 text-white"
            >
              Save
            </button>
            {/*  */}
          </div>
        )}
      </div>
    </div>
  );
}
export default SubActivityModal;
