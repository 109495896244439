import Checkbox from "components/checkbox";
import Error from "components/error";
import Loading from "components/loading";
import { useEffect, useState } from "react";
import avatar from "assets/img/avatars/dummy.png";

function ExpertsDropdown({
  experts,
  selectedExpert,
  setSelectedExpert,
  loading,
  error,
  assignExpert,
}) {
  const [filteredExperts, setFilteredExperts] = useState(experts);
  const handleSearch = (e) => {
    const value = e.target.value;

    if (value !== "") {
      const filtered = experts.filter(
        (expert) =>
          expert?.profile?.first_name
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          expert?.profile?.last_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredExperts(filtered);
      return;
    }
    setFilteredExperts(experts);
  };

  useEffect(() => {
    setFilteredExperts(experts);
  }, [experts]);

  return (
    <div className="rounded-lg bg-white px-6 py-4 shadow-xl dark:bg-navy-700 dark:text-white dark:shadow-xl">
      <input
        type="text"
        placeholder="Search"
        className="h-9 w-full rounded-lg border border-gray-200 px-3 text-sm text-gray-900 placeholder:text-gray-900 dark:bg-navy-700 dark:text-white dark:placeholder-gray-400"
        onChange={handleSearch}
      />
      {loading && <Loading />}
      {error && <Error error={error} />}
      {!loading &&
        filteredExperts?.map((expert, index) => (
          <div
            key={index}
            className="my-3 flex items-center gap-3 rounded-lg px-3 py-1.5"
          >
            <Checkbox
              value={expert?.profile?.user}
              checked={selectedExpert?.profile?.user === expert?.profile?.user}
              onChange={() => setSelectedExpert(expert)}
              extra={`!rounded-md`}
            />
            <img
              src={expert?.profile?.file || avatar}
              alt="profile"
              className="h-5 w-5 rounded-full"
            />
            {expert?.profile ? (
              <p className="text-sm text-gray-800 dark:text-white">
                {expert?.profile?.first_name} {expert?.profile?.last_name}
              </p>
            ) : (
              <p className="text-sm text-gray-800 dark:text-white">
                {expert.email}
              </p>
            )}
          </div>
        ))}
      <div className="mt-9 flex justify-end gap-2">
        <button
          className="rounded-lg bg-[#5E5ADB] py-2 px-8 text-white hover:bg-navy-500"
          onClick={assignExpert}
        >
          Assign
        </button>
      </div>
    </div>
  );
}
export default ExpertsDropdown;
