/* eslint-disable react-hooks/exhaustive-deps */
import useAxios from "axios-hooks";
import Dropdown from "components/dropdown";
import Error from "components/error";
import BackIcon from "components/icons/BackIcon";
import Loading from "components/loading";
import { TLRouteNameContext } from "layouts/teamLeader";
import { Fragment, Suspense, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoCheckmark, IoDocument, IoPersonAdd } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import ProjectMilestone from "views/admin/ceo/components/ProjectMilestone";
import ExpertsDropdown from "views/admin/team-leader/components/ExpertsDropdown";

function ProjectDetails() {
  let navigate = useNavigate();
  const params = useParams();
  const { setCurrentRoute, currentRoute } = useContext(TLRouteNameContext);

  const [selectedExpert, setSelectedExpert] = useState({
    id: null,
    first_name: "",
    last_name: "",
    profile: {
      file: "",
    },
  });

  const [{ data: project, loading, error }, fetchProject] = useAxios(
    {
      url: `/main_app/projects/${params.id}/`,
      method: "GET",
    },
    { manual: true }
  );

  const [{ data: milestones, error: milestoneError }, fetchMilestones] =
    useAxios(
      {
        url: `/main_app/milestones/by_project/${params.id}/`,
        method: "GET",
      },
      { manual: true, useCache: false }
    );

  const [{ data: experts, error: expertsError }] = useAxios(
    {
      url: `/account/expert-users/`,
      method: "GET",
    },
    { useCache: false }
  );

  const [{ loading: putLoading, error: putError }, assignExpert] = useAxios(
    {
      url: `/main_app/projects/${params.id}/assign/`,
      method: "PUT",
    },
    { manual: true }
  );

  const [{ loading: planLoading, error: planError }, executePlan] = useAxios(
    {
      url: `/main_app/projects/${project?.id}/execution-plan-status/`,
      method: "PUT",
    },
    { manual: true }
  );

  const handleAssignExpert = async () => {
    try {
      await assignExpert({
        data: {
          assigned_to: selectedExpert?.profile?.user,
        },
      });
      toast.success("Expert assigned successfully", {
        icon: <IoCheckmark />,
        style: { color: "green" },
      });
      fetchProject();
    } catch (error) {
      console.log(error);
    }
  };

  const handleExecutePlan = async (status) => {
    try {
      await executePlan({
        data: {
          execution_plan_status: status,
          feedback: document.getElementById("executionPlanComment").value,
        },
      });

      toast.success(`Execution plan ${status} successfully`, {
        icon: <IoCheckmark />,
        style: { color: "green" },
      });

      fetchProject();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (params.id) {
      fetchProject();
      fetchMilestones();
    }
  }, [params.id]);

  useEffect(() => {
    if (project && currentRoute !== `${project?.project_name} Detail`) {
      setCurrentRoute(`${project?.project_name} Detail`);
    }
    return () => {};
    // eslint-disable-next-line
  }, [project, currentRoute]);

  return (
    <div className="mt-5 ml-3 fill-blueSecondary font-poppins">
      {!error ? (
        loading ? (
          <Loading />
        ) : (
          <>
            <div className="flex flex-wrap justify-between">
              <div>
                <BackIcon
                  onClick={() => {
                    navigate(-1, { replace: true });
                  }}
                  className="cursor-pointer"
                />
                <div className="ml-12 flex items-start gap-3">
                  <div className="h-4 w-4 bg-orange-400" />
                  <div>
                    <p className="text-3xl font-semibold text-gray-700 dark:text-white ">
                      Project Detail
                    </p>
                    {project?.execution_plan_status !== "pending" && (
                      <div
                        className="mt-2 flex w-fit items-center gap-2 rounded-md px-3 py-0.5"
                        style={{
                          backgroundColor:
                            project?.execution_plan_status === "approved"
                              ? "#E1FCEF"
                              : "#FFEDEF",
                        }}
                      >
                        <div
                          className={`h-2 w-2 ${
                            project?.execution_plan_status === "approved"
                              ? "bg-green-500"
                              : "bg-red-800"
                          }`}
                        />
                        <p
                          className={`text-sm font-[400] capitalize ${
                            project?.execution_plan_status === "approved"
                              ? "text-green-800"
                              : "text-red-800"
                          }`}
                        >
                          {project?.execution_plan_status}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/*  */}
              {project?.execution_plan_status === "pending" && (
                <div className="mt-5 flex items-center gap-3">
                  <Dropdown
                    button={
                      <button className="flex items-center gap-2 rounded-lg bg-[#485FE5] px-10 py-3 text-sm text-white">
                        <IoPersonAdd size={25} />
                        Assign Expert / Coordinator
                      </button>
                    }
                    classNames={"top-16 min-w-[300px] "}
                    animation={
                      "origin-top transition-all duration-300 ease-in-out"
                    }
                    id="expertsDropdown"
                    children={
                      expertsError ? (
                        <Error error={error} />
                      ) : (
                        <ExpertsDropdown
                          experts={experts}
                          selectedExpert={selectedExpert}
                          setSelectedExpert={setSelectedExpert}
                          loading={putLoading}
                          error={putError}
                          assignExpert={handleAssignExpert}
                        />
                      )
                    }
                  />
                </div>
              )}
              {/*  */}
            </div>
            <div className="gap-2 3xl:flex">
              <div className="mt-5 flex-grow rounded-lg bg-[#F2F9FFCC] py-3 px-4 dark:bg-navy-700">
                <h1 className="ml-2 font-semibold text-navy-500">
                  Description
                </h1>
                <p className="mt-2 ml-2 w-full rounded-lg bg-white p-3 text-sm text-[#787486] dark:bg-navy-900 dark:text-white ">
                  {project?.project_description}
                </p>

                {/* Milestones */}
                {milestoneError && <Error error={milestoneError} />}

                <Suspense fallback={<Loading />}>
                  {milestones?.results?.map((milestone) => (
                    <ProjectMilestone
                      key={milestone.id}
                      data={{
                        ...milestone,
                        project_status: project?.status,
                      }}
                    />
                  ))}
                </Suspense>
              </div>

              <div className="w-full 3xl:w-1/4">
                <div className="mt-5 h-fit max-w-full rounded-lg bg-[#F2F9FFCC] py-5 px-6 font-poppins dark:bg-navy-700">
                  <p className="text-lg font-semibold text-gray-700 dark:text-white ">
                    Files
                  </p>
                  {project?.files?.map((file) => (
                    <div
                      key={file.id}
                      className="mt-8 flex flex-wrap items-start gap-5 "
                    >
                      <IoDocument color="#2F80ED" size={45} />
                      <p className="text-sm dark:text-white">
                        {file.file.split("/").pop().split(".")[0]}
                      </p>
                      <a
                        href={file.file}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-pointer text-sm text-blueSecondary underline"
                      >
                        open
                      </a>
                    </div>
                  ))}
                </div>
                {/*  */}
                {project?.execution_plan_status === "pending" && (
                  <div className="mt-5 h-fit  rounded-lg bg-[#F2F9FFCC] py-5 px-6 font-poppins dark:bg-navy-700">
                    {planError && <Error error={planError} />}
                    {planLoading ? (
                      <Loading />
                    ) : (
                      <Fragment>
                        <h1 className="text-xl font-semibold text-gray-700 dark:text-white ">
                          Comment
                        </h1>
                        <div className="mt-3 flex flex-col gap-5">
                          <label htmlFor="comment" className="text-gray-600">
                            Write reason to Approve/Reject
                          </label>
                          <textarea
                            id="executionPlanComment"
                            className="mt-2 h-24 w-full rounded-lg border border-gray-200 p-2 dark:border-navy-700 dark:bg-navy-900 dark:text-white"
                            rows={5}
                          />
                          <p id="rejectError" className="hidden text-red-600">
                            Please write a reason
                          </p>
                        </div>
                        <div className="mt-5 flex justify-end gap-6">
                          <button
                            className="rounded-md bg-[#F8E5E5] py-2.5 px-5 text-[#CC362F] hover:bg-red-400 hover:text-white 3xl:px-7"
                            onClick={() => {
                              if (
                                document.getElementById("executionPlanComment")
                                  ?.value.length === 0
                              ) {
                                document
                                  .getElementById("rejectError")
                                  .classList.replace("hidden", "block");
                              } else {
                                handleExecutePlan("rejected");
                              }
                            }}
                          >
                            Reject
                          </button>
                          <button
                            className="rounded-md bg-[#485FE5] py-2.5 px-5 text-white hover:bg-navy-500 3xl:px-7"
                            onClick={() => handleExecutePlan("approved")}
                          >
                            Approve
                          </button>
                        </div>
                      </Fragment>
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )
      ) : (
        <Error error={error} />
      )}
    </div>
  );
}

export default ProjectDetails;
