import PieChart from "components/charts/PieChart";
import Card from "components/card";
import { colors } from "helpers/StaticValues";

const PieChartCard = ({ title, data }) => {
  return (
    <Card extra="rounded-[20px] flex-grow p-3 shadow-md text-gray-700 dark:text-white bg-[#F5FAFF]">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold ">{title || "Your Pie Chart"}</h4>
        </div>
      </div>

      <div className="mb-auto flex h-[160px] w-full items-center justify-center">
        {data && (
          <PieChart
            options={{
              labels: [
                "Completed Activities",
                "Ongoing Activities",
                "Pending Activities",
              ],
              colors: colors,
              chart: {
                width: "50px",
              },
              states: {
                hover: {
                  filter: {
                    type: "none",
                  },
                },
              },
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false,
              },
              hover: { mode: null },
              plotOptions: {
                donut: {
                  expandOnClick: false,
                  donut: {
                    labels: {
                      show: false,
                    },
                  },
                },
              },
              fill: {
                colors: colors,
              },
              tooltip: {
                enabled: true,
                theme: "dark",
                style: {
                  fontSize: "12px",
                  fontFamily: undefined,
                  backgroundColor: "#000000",
                },
              },
            }}
            series={[
              data?.completed_activities || 0,
              data?.ongoing_activities || 0,
              data?.pending_activities || 0,
            ]}
          />
        )}
      </div>
      <div className="rounded-lg bg-gray-100 p-4 dark:bg-navy-600 3xl:mx-5">
        <p className="text-sm">Activities</p>
        <p className="text-xl font-bold">{data?.total_activities}</p>
      </div>
      <div className="flex flex-col items-start gap-1 rounded-2xl px-6 py-3 3xl:mx-5">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-sm bg-brand-500" />
            <p className="ml-1 text-sm font-normal text-gray-600">
              <span className="font-bold text-gray-800 dark:text-white">
                {data?.completed_activities}
              </span>{" "}
              Completed Activities
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-sm bg-[#6AD2FF]" />
            <p className="ml-1 text-sm font-normal text-gray-600">
              <span className="font-bold text-gray-800 dark:text-white">
                {data?.ongoing_activities}
              </span>{" "}
              Ongoing Activities
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-sm bg-[#570505]" />
            <p className="ml-1 text-sm font-normal text-gray-600">
              <span className="font-bold text-gray-800 dark:text-white">
                {data?.pending_activities}
              </span>{" "}
              Pending Activities
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PieChartCard;
