import Chart from "react-apexcharts";

const AreaChart = (props) => {
  const { series, options } = props;

  return (
    <Chart
      options={options}
      type="area"
      width="100%"
      height="100%"
      series={series}
    />
  );
};

export default AreaChart;
