const SectorIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5.00023C3 3.91323 3.893 2.96023 5.062 3.03323C9.19553 3.29192 13.0924 5.05066 16.021 7.97923C18.9496 10.9078 20.7083 14.8047 20.967 18.9382C21.04 20.1072 20.087 21.0002 19 21.0002H5C4.46957 21.0002 3.96086 20.7895 3.58579 20.4144C3.21071 20.0394 3 19.5307 3 19.0002V5.00023ZM5 5.03323V19.0002H18.967C18.7249 15.3756 17.1757 11.962 14.6069 9.39328C12.0382 6.82458 8.62463 5.27533 5 5.03323Z"
      // fill="#707070"
    />
  </svg>
);

export default SectorIcon;
