/* eslint-disable react-hooks/exhaustive-deps */
import useAxios from "axios-hooks";
import Error from "components/error";
import BackIcon from "components/icons/BackIcon";
import Loading from "components/loading";
import { CeoRouteNameContext } from "layouts/ceo";
import { Suspense, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProjectMilestone from "views/admin/ceo/components/ProjectMilestone";
import ProjectStatus from "views/admin/ceo/components/ProjectStatus";

function ProjectDetails({ context }) {
  let navigate = useNavigate();
  const params = useParams();
  const { setCurrentRoute } = useContext(context || CeoRouteNameContext);

  const [{ data: project, loading, error }, fetchProject] = useAxios(
    {
      url: `/main_app/projects/${params.id}/`,
      method: "GET",
    },
    { manual: true }
  );
  const [{ data: milestones, error: milestoneError }, fetchMilestones] =
    useAxios(
      {
        url: `/main_app/milestones/by_project/${params.id}/`,
        method: "GET",
      },
      { manual: true }
    );

  useEffect(() => {
    if (params.id) {
      fetchProject();
      fetchMilestones();
    }
  }, [params.id]);

  useEffect(() => {
    if (project) {
      setCurrentRoute(`${project?.project_name} Details`);
    }
  }, [project]);

  return (
    <div className="mt-5 ml-3 fill-blueSecondary font-poppins">
      {!error ? (
        loading ? (
          <Loading />
        ) : (
          <>
            <div className="flex gap-8">
              <BackIcon
                onClick={() => {
                  navigate(-1, { replace: true });
                }}
                className="cursor-pointer"
              />
              <div className="mt-4 flex items-start gap-3">
                <div className="h-4 w-4 bg-orange-400" />
                <div>
                  <p className="text-3xl font-semibold text-gray-700 dark:text-white ">
                    Project Detail
                  </p>
                  <p className="mt-2 space-x-6 font-semibold tracking-widest text-gray-700 dark:text-white ">
                    Project ID:
                    <span className="font-normal tracking-widest text-gray-500">
                      {" "}
                      #{project?.id}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="gap-2 3xl:flex">
              <div className="mt-5 flex-grow rounded-lg bg-[#F2F9FFCC] py-3 px-4 dark:bg-navy-700">
                <h1 className="ml-2 font-semibold text-navy-500">
                  Description
                </h1>
                <p className="mt-2 ml-2 w-full rounded-lg bg-white p-3 text-sm text-[#787486] dark:bg-navy-900 dark:text-white ">
                  {project?.project_description}
                </p>

                {/* Milestones */}
                {milestoneError && <Error error={milestoneError} />}

                <Suspense fallback={<Loading />}>
                  {milestones?.results?.map((milestone) => (
                    <ProjectMilestone
                      key={milestone.id}
                      data={{
                        ...milestone,
                        project_status: project?.status,
                      }}
                    />
                  ))}
                </Suspense>
              </div>
              <ProjectStatus project={{ ...project, milestones }} />
            </div>
          </>
        )
      ) : (
        <Error error={error} />
      )}
    </div>
  );
}

export default ProjectDetails;
