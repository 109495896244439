// Admin Imports
import MainDashboard from "views/admin/default";

// Auth Imports

// Icon Imports
import ProjectsList from "views/admin/ceo/ProjectsList";
import StaffMembers from "views/admin/ceo/StaffMembers";
import Project from "components/icons/Project";
import StaffMembersIcon from "components/icons/StaffMembersIcon";
import ProjectDetails from "views/admin/ceo/ProjectDetails";
import HomeIcon from "components/icons/HomeIcon";
import UsersList from "views/admin/default/UsersList";
import UserIcon from "components/icons/UserIcon";
import BaseDataIcon from "components/icons/BaseDataIcon";
import BaseData from "views/admin/default/BaseData";
import CeoDashboard from "views/admin/ceo";
import TLDashboard from "views/admin/team-leader";
import ProjectsListTL from "views/admin/team-leader/ProjectsList";
import StaffMembersTL from "views/admin/team-leader/StaffMembers";
import ProjectDetailsTL from "views/admin/team-leader/ProjectDetails";
import ExpertProjectsList from "views/admin/expert/ProjectsList";
import HODashboard from "views/admin/HO";
import HOProjectsList from "views/admin/HO/ProjectsList";

const routes = [
  {
    name: "Home",
    layout: "/admin",
    path: "home",
    icon: <HomeIcon />,
    component: <MainDashboard />,
  },

  {
    name: "User Management",
    layout: "/admin",
    icon: <UserIcon />,
    path: "users",
    component: <UsersList />,
  },
  {
    name: "Base Data",
    layout: "/admin",
    path: "base-data",
    icon: <BaseDataIcon />,
    component: <BaseData />,
  },
];

export const ceoRoutes = [
  {
    name: "Home",
    title: "Home",
    layout: "/ceo",
    path: "home",
    icon: <HomeIcon />,
    component: <CeoDashboard />,
  },

  {
    name: "Project",
    title: "Projects List",
    layout: "/ceo",
    icon: <Project extra="h-6 w-6 fill-white" />,
    path: "projects",
    component: <ProjectsList />,
    children: [
      {
        name: "Project Details",
        layout: "/ceo",
        icon: <Project extra="h-6 w-6 fill-white" />,
        path: ":id",
        component: <ProjectDetails />,
      },
    ],
  },
  {
    name: "Staff members",
    title: "Staff Members",
    layout: "/ceo",
    path: "staff",
    icon: <StaffMembersIcon className="h-6 w-6" />,
    component: <StaffMembers />,
  },
];

export const teamLeaderRoutes = [
  {
    name: "Home",
    layout: "/team-leader",
    path: "home",
    icon: <HomeIcon />,
    component: <TLDashboard />,
  },

  {
    name: "Project",
    layout: "/team-leader",
    icon: <Project extra="h-6 w-6 fill-white" />,
    path: "projects",
    component: <ProjectsListTL />,
    children: [
      {
        name: "Project Details",
        layout: "/team-leader",
        icon: <Project extra="h-6 w-6 fill-white" />,
        path: ":id",
        component: <ProjectDetailsTL />,
      },
    ],
  },
  {
    name: "Staff members",
    layout: "/team-leader",
    path: "staff",
    icon: <StaffMembersIcon className="h-6 w-6" />,
    component: <StaffMembersTL />,
  },
];

export const expertRoutes = [
  // {
  //   name: "Home",
  //   layout: "/expert",
  //   path: "home",
  //   icon: <HomeIcon />,
  //   component: <ExpertDashboard />,
  // },

  {
    name: "Project Lists",
    layout: "/expert",
    icon: <Project extra="h-6 w-6 fill-white" />,
    path: "projects",
    component: <ExpertProjectsList />,
  },
];

export const higherOfficialRoutes = [
  {
    name: "Home",
    layout: "/higher-official",
    path: "home",
    icon: <HomeIcon />,
    component: <HODashboard />,
  },
  {
    name: "Projects",
    layout: "/higher-official",
    path: "projects",
    icon: <Project extra="h-6 w-6 fill-white" />,
    component: <HOProjectsList />,
  },
];

export default routes;
