import Card from "components/card";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";

function ActivityProgress({ data, link = "ceo" }) {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <Card extra="rounded-[20px] flex flex-col gap-3 2xl:px-3 pt-5 md:col-span-2 shadow text-gray-700 dark:text-white bg-[#F5FAFF] font-poppins">
      <h1 className="mt-2 ml-3 text-xl text-[#A3AED0]">Milestone Progress</h1>

      <table className=" mt-6 table-fixed overflow-auto text-start">
        <thead>
          <tr>
            <th className="px-4">#</th>
            <th className="">Milestone Name</th>
            <th className="px-2">Progress</th>
            <th className="px-4">KPI</th>
          </tr>
        </thead>
        <tbody className="text-[#A3AED0] ">
          {data?.map((item) => (
            <tr key={item.milestone_id} className="my-12 table-row">
              <td className="px-3 2xl:p-4">
                {Number(item?.milestone_id).toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })}
              </td>
              <td className="w-fit ">{item.milestone_name}</td>
              <td className="px-3">
                <div className="mt-2 flex h-3 rounded-full bg-white dark:!bg-navy-700 3xl:min-w-[210px]">
                  <span
                    className="h-full rounded-full bg-[#485FE5] dark:!bg-white"
                    style={{ width: item.progress_percentage + "%" }}
                  />
                </div>
              </td>
              <td className=" rounded-xl px-2 text-center text-[#FCB859]">
                <p className="rounded-xl border border-[#FCB859] bg-[#FCB8591F] p-2">
                  {item.progress_percentage}%
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        className="mr-2 mt-auto flex items-center gap-2 self-end pr-1 pb-4 font-semibold text-brand-800 dark:text-navy-100"
        onClick={() => {
          navigate(`/${link}/projects/${params?.id}`);
        }}
      >
        <p>View all</p>
        <BsArrowRight size={20} />
      </button>
    </Card>
  );
}

export default ActivityProgress;
