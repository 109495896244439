import Card from "components/card";
import AreaChart from "components/charts/AreaChart";
const ProjectsByMonth = ({ data }) => {
  return (
    <Card extra="px-3 py-3 h-full bg-[#e6e6e659]/10 shadow-md">
      <div className="ml-1 mb-2 pt-2">
        <p className="text-base font-[500] leading-4 text-[#787486]">
          Registered Projects per Month
        </p>
      </div>

      {data && (
        <AreaChart
          series={[
            {
              name: "Projects",
              data: data?.map((item) => item.count),
            },
          ]}
          options={{
            chart: {
              height: 280,
              type: "area",
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
            grid: {
              show: false,
            },

            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],
              },
            },
            colors: ["#00D9A5"],
            xaxis: {
              categories: data?.map((item) => item.month.substring(0, 3)),
            },
          }}
        />
      )}
    </Card>
  );
};

export default ProjectsByMonth;
