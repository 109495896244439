import PieChart from "components/charts/PieChart";
import Card from "components/card";

const PieChartCard = ({ title, data, colors }) => {
  return (
    <Card extra="rounded-[20px] shadow p-3 text-gray-700 dark:text-white bg-[#FAFAFA80]">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold ">{title || "Your Pie Chart"}</h4>
        </div>
      </div>

      <div className="mb-auto flex h-[220px] w-full items-center justify-center">
        {data && (
          <PieChart
            options={{
              labels: data?.map(
                (item) =>
                  item.project_category__project_category_name ||
                  item.region__region_name ||
                  item.sector__sector_name
              ),
              colors: colors,
              chart: {
                width: "50px",
              },
              states: {
                hover: {
                  filter: {
                    type: "none",
                  },
                },
              },
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false,
              },
              hover: { mode: null },
              plotOptions: {
                donut: {
                  expandOnClick: false,
                  donut: {
                    labels: {
                      show: false,
                    },
                  },
                },
              },
              fill: {
                colors: colors,
              },
              tooltip: {
                enabled: true,
                theme: "dark",
                style: {
                  fontSize: "12px",
                  fontFamily: undefined,
                  backgroundColor: "#000000",
                },
              },
            }}
            series={data?.map((item) => item.count)}
          />
        )}
      </div>
      <div className="mx-4 rounded-lg bg-gray-100 p-4 dark:bg-navy-600">
        <p className="text-sm">{title || "Projects by Categories"}</p>
        <p className="text-xl font-bold">
          {data?.reduce((acc, item) => acc + item.count, 0)}
        </p>
      </div>
      <div className="flex flex-col items-start gap-1 rounded-2xl px-6 py-3">
        {data?.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center"
          >
            <div className="flex items-center justify-center">
              <div
                className={"h-2 w-2 rounded-md"}
                style={{ backgroundColor: colors[index] }}
              />
              <p className="ml-1 text-sm font-normal text-gray-600">
                <span className="font-bold text-gray-800 dark:text-white">
                  {item.count}
                </span>{" "}
                {item.project_category__project_category_name ||
                  item.region__region_name}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default PieChartCard;
